//frontend/src/components/WelcomeModal.tsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { debounce } from 'lodash';
import axiosInstance from '../utils/axiosConfig';

interface Location {
  display_name: string;
  lat: string;
  lon: string;
}

interface WelcomeModalProps {
  open: boolean;
  onLocationSet: (latitude: number, longitude: number) => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ open, onLocationSet }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Location[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchCitySuggestions = async (query: string) => {
    if (query.length < 3) return; // Don't search if query is less than 3 characters
    try {
      setLoading(true);
      const response = await axiosInstance.get('/api/locationiq/cities', {
        params: { q: query },
      });
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching city suggestions:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchCitySuggestions = useCallback(
    debounce((query: string) => fetchCitySuggestions(query), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedFetchCitySuggestions.cancel();
    };
  }, [debouncedFetchCitySuggestions]);

  const handleLocationSelect = (location: Location | null) => {
    if (location) {
      onLocationSet(parseFloat(location.lat), parseFloat(location.lon));
    }
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle>Welcome to CowboyQuote!</DialogTitle>
      <DialogContent>
        <p>Where are you going to be hanging quotes? (Enter city name)</p>
        <Autocomplete
          freeSolo
          options={options}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.display_name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter your city"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'warning.main',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'warning.main', 
                },
              }}
            />
          )}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            if (newInputValue.length >= 3) {
              debouncedFetchCitySuggestions(newInputValue);
            } else {
              setOptions([]);
            }
          }}
          onChange={(event, newValue) => {
            if (typeof newValue === 'object' && newValue !== null) {
              handleLocationSelect(newValue);
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeModal;