//frontend/src/components/QuotaIndicator.tsx
import React, { useState, useCallback } from 'react';
import { Box, CircularProgress, Typography, Popover, LinearProgress } from '@mui/material';
import { useRemainingQuotes } from '../RemainingQuotesContext';
import { Link } from 'react-router-dom';

const QuotaIndicator: React.FC = () => {
  const { remainingQuotes, monthlyLimit, refreshRemainingQuotes } = useRemainingQuotes();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getFontSize = useCallback(() => {
    if (remainingQuotes === null || monthlyLimit === null) return '12px';
    const totalDigits = (remainingQuotes.toString() + monthlyLimit.toString()).length;
    if (totalDigits <= 4) return '12px';
    if (totalDigits <= 6) return '10px';
    return '8px';
  }, [remainingQuotes, monthlyLimit]);

  if (remainingQuotes === null || monthlyLimit === null) return null;

  const percentage = (remainingQuotes / monthlyLimit) * 100;

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          position: 'fixed',
          top: 20,
          right: 20,
          width: 60,
          height: 60,
          borderRadius: '50%',
          backgroundColor: 'background.paper',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: 3,
          cursor: 'pointer',
          transition: 'transform 0.3s',
          '&:hover': {
            transform: 'scale(1.1)',
          },
          zIndex: 9999,
        }}
      >
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={56}
          thickness={4}
          color='warning'
        />
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: getFontSize(),
            lineHeight: 1,
            textAlign: 'center',
          }}
        >
          {`${remainingQuotes}\n/${monthlyLimit}`}
        </Typography>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ zIndex: 10000 }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="h6" gutterBottom>
            Quote Generation Quota
          </Typography>
          <Typography variant="body2" gutterBottom>
            You have {remainingQuotes} out of {monthlyLimit} quotes remaining this month.
          </Typography>
          <Box sx={{ width: '100%', mt: 2, mb: 2 }}>
            <LinearProgress variant="determinate" value={percentage} color='warning'/>
          </Box>
          <Link to="/dashboard/subscription" style={{ textDecoration: 'none' }}>
  <Typography variant="body2" color="warning.main" sx={{ cursor: 'pointer', textAlign: 'center' }}>
    Manage Subscription
  </Typography>
</Link>
        </Box>
      </Popover>
    </>
  );
};

export default QuotaIndicator;