// src/utils/publicAxios.js

import axios from 'axios';

const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
    headers: {
        'Content-Type': 'application/json',
    }
});

// No interceptors needed since these are public routes

export default publicAxios;