import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 3,
        backgroundColor: '#052F46',
        color: 'white',
        width: '100%',
      }}
    >
      <Typography variant="body2" align="center">
        {'Instant Quotes by © '}
        <Link color="inherit" href="https://cowboyquote.com/">
          CowboyQuote
        </Link>{' '}
        </Typography>
        <Typography variant="body2" align="center">   
        {new Date().getFullYear()}
        {'. All rights reserved.'}
        </Typography>
    </Box>
  );
};

export default Footer;