// Dashboard.tsx
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { 
  Box, Typography, Grid, Card, CardContent, IconButton, CircularProgress, Tooltip, Button 
} from '@mui/material';
import { 
  Home, Description, FileCopy, Build, Bungalow, Settings as SettingsIcon, Logout as LogoutIcon, 
  Brightness4, Brightness7 
} from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Quotes from './Quotes';
import Customers from './Customers';
import Logout from './Logout';
import Templates from './Templates';
import Settings from './Settings';
import ViewQuote from './ViewQuote';
import CreateNew from './CreateNew';
import WelcomeModal from './WelcomeModal'; 
import TermsOfServiceModal from './TermsOfServiceModal';
import PredefinedServicesPage from './PredefinedServicesPage';
import SubscriptionManager from './SubscriptionManager';
import ViewAllQuotes from './ViewAllQuotes';
import { ColorModeContext } from '../App';
import { useTheme } from '@mui/material/styles';
import QuoteMap from './QuoteMap';
import { useAuth } from '../AuthContext';

// TypeScript Interfaces
interface Quote {
  quoteId: string;
  templateId: string;
  templateName: string;
  customerName: string;
  services: string[];
  serviceValues: string[];
  location?: {
    latitude: number;
    longitude: number;
  };
  activatedTime: string;
  acceptedTime: string;
  totalValue: number;
  status: 'Active' | 'Accepted';
}

interface QuoteTemplate {
  templateId: string;
  name: string;
  services: string[];
  companyName: string;
  createdBy: string;
  createdAt: string;
}

interface User {
  userId: string;
  name: string;
  email: string;
  companyName: string;
  role: string;
}

interface Customer {
  quoteId: string;
  addressLocation?: {
    latitude: number;
    longitude: number;
  };
  firstName: string;
  lastName: string;
}

const TOS_VERSION = '1.0.0';

const Dashboard: React.FC = () => {
  // State Variables
  const [activeQuotes, setActiveQuotes] = useState<Quote[]>([]);
  const [acceptedQuotes, setAcceptedQuotes] = useState<Quote[]>([]);
  const [preparedActiveQuotes, setPreparedActiveQuotes] = useState<Quote[]>([]);
  const [preparedAcceptedQuotes, setPreparedAcceptedQuotes] = useState<Quote[]>([]);
  const [quoteTemplates, setQuoteTemplates] = useState<QuoteTemplate[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [companySettings, setCompanySettings] = useState<any>(null);

  // Contexts and Hooks
  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  // Ref to prevent multiple fetches (e.g., due to React Strict Mode)
  const hasFetched = useRef(false);

  /**
   * Fetch customer data for a given quoteId and companyName.
   * @param quoteId - The ID of the quote.
   * @param companyName - The name of the company.
   * @returns Customer data or null if an error occurs.
   */
  const fetchCustomerData = useCallback(async (quoteId: string, companyName: string): Promise<Customer | null> => {
    try {
     /*  console.log(`Fetching customer data for Quote ID: ${quoteId}, Company: ${companyName}`); */

      const response = await axiosInstance.get(`/api/customers/customerByQuote/${quoteId}`, {
        params: { companyName: companyName }
      });

      return response.data;
    } catch (error: any) {
      console.error(`Error fetching customer data for quote ${quoteId}:`, error.response?.data || error.message);
      return null;
    }
  }, []);

  /**
   * useEffect hook to fetch dashboard data.
   * Runs once when the component mounts.
   */
  useEffect(() => {
    // If user is not authenticated, redirect to login
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    // Prevent multiple fetches
    if (hasFetched.current) {
      return;
    }

    hasFetched.current = true;

    /**
     * Fetch all necessary dashboard data.
     */
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Retrieve user data from localStorage
        const userDataString = localStorage.getItem('userData');
        if (!userDataString) {
          throw new Error('User data not found');
        }
        const userData: User = JSON.parse(userDataString);
        setUser(userData);

        // Check Terms of Service (ToS) status
        const tosResponse = await axiosInstance.get('/api/tos/status');
        if (!tosResponse.data.tosAccepted || tosResponse.data.tosAcceptedVersion !== TOS_VERSION) {
          setShowTosModal(true);
        } else {
          setTosAccepted(true);

          // Fetch company settings after ToS is accepted
          const compSettingsResponse = await axiosInstance.get('/api/compSettings');
          setCompanySettings(compSettingsResponse.data);

          // If company location is not set, show welcome modal
          if (!compSettingsResponse.data.compLocation) {
            setShowWelcomeModal(true);
          }

          // Proceed to fetch quotes and related data
          await fetchQuoteData(userData);
        }

        setLoading(false);
      } catch (error: any) {
        console.error('Error fetching dashboard data:', error.response?.data || error.message);
        setError('Failed to load dashboard data. Please try again.');
        setLoading(false);
      }
    };

    /**
     * Fetch quote templates, active quotes, accepted quotes, and their corresponding customer data.
     * @param userData - The authenticated user's data.
     */
    const fetchQuoteData = async (userData: User) => {
      try {
        // Fetch quote templates, active quotes, and all accepted quotes concurrently
        const [templatesResponse, activeQuotesResponse, acceptedQuotesResponse] = await Promise.all([
          axiosInstance.get('/api/quoteTemplates'),
          axiosInstance.get(`/api/quotes/active/${userData.userId}`),
          axiosInstance.get(`/api/quotes/all/${userData.userId}`, {
            params: {
              status: 'Accepted',
              startDate: '1970-01-01',
            }
          })
        ]);

        setQuoteTemplates(templatesResponse.data);
        setActiveQuotes(activeQuotesResponse.data);

        // Filter accepted quotes to only those accepted within the last week
        const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
        const filteredAcceptedQuotes: Quote[] = acceptedQuotesResponse.data
          .filter((quote: Quote) => new Date(quote.acceptedTime).getTime() >= oneWeekAgo)
          .sort((a: Quote, b: Quote) => new Date(b.acceptedTime).getTime() - new Date(a.acceptedTime).getTime());

        setAcceptedQuotes(filteredAcceptedQuotes);

        // Prepare active quotes data with popup content
        const preparedActive = activeQuotesResponse.data.map((quote: Quote) => ({
          ...quote,
          popupContent: `
            <strong>${quote.templateName}</strong><br/>
            Activated: ${new Date(quote.activatedTime).toLocaleString()}<br/>
            Total Value: $${quote.totalValue.toFixed(2)}
          `
        }));
        setPreparedActiveQuotes(preparedActive);

        // Fetch customer data for each accepted quote
        const preparedAcceptedPromises = filteredAcceptedQuotes.map(async (quote: Quote) => {
          const customer = await fetchCustomerData(quote.quoteId, userData.companyName);
          return {
            ...quote,
            location: customer?.addressLocation,
            popupContent: `
              <strong>${quote.customerName}</strong><br/>
              Accepted: ${new Date(quote.acceptedTime).toLocaleString()}<br/>
              Total Value: $${quote.totalValue.toFixed(2)}
            `
          };
        });

        const preparedAccepted = await Promise.all(preparedAcceptedPromises);

        // Filter out quotes without a location
        setPreparedAcceptedQuotes(preparedAccepted.filter(quote => quote.location));
      } catch (error: any) {
        console.error('Error fetching quote data:', error.response?.data || error.message);
        setError('Failed to load quote data. Please try again.');
      }
    };

    // Initiate data fetching
    fetchDashboardData();
  }, [fetchCustomerData, isAuthenticated, navigate]);

  /**
   * Handle completion of ToS acceptance.
   */
  const handleTosAcceptComplete = useCallback(() => {
    setTosAccepted(true);
    setShowTosModal(false);
  }, []);

  /**
   * Toggle the sidebar's expanded state.
   */
  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  /**
   * Handle click on a quote template.
   * @param template - The selected quote template.
   */
  const handleTemplateClick = (template: QuoteTemplate) => {
    navigate('/dashboard/templates', { state: { selectedTemplate: template } });
  };

  /**
   * Handle setting of company location.
   * @param latitude - Latitude of the location.
   * @param longitude - Longitude of the location.
   */
  const handleLocationSet = async (latitude: number, longitude: number) => {
    try {
      const response = await axiosInstance.post('/api/compSettings/setLocation', {
        latitude,
        longitude,
      });
      setCompanySettings(response.data);
      setShowWelcomeModal(false);
    } catch (error: any) {
      console.error('Error setting location:', error.response?.data || error.message);
    }
  };

  /**
   * Render the home section of the dashboard.
   */
  const DashboardHome = () => (
    <>
      <Typography variant="h5" align='center' gutterBottom sx={{fontWeight:'bold', mt: 4}}>
        Howdy {user?.name ? user.name.split(' ')[0] : 'User'}
      </Typography>

      {/* Recently Accepted Quotes */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Recently Accepted Quotes
        </Typography>
        <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2, mt: 2, pb: 1 }}>
          {preparedAcceptedQuotes.map((quote) => (
            <Card
              key={quote.quoteId}
              onClick={() => navigate(`/dashboard/internal/quote/${quote.quoteId}`)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3,
                },
                minWidth: 250,
                flex: '0 0 auto',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Bungalow sx={{ fontSize: 40, color: 'warning.main', mb: 1, mt: 1 }} />
                <Typography variant="subtitle2" noWrap>{quote.customerName}</Typography>
                <Typography variant="body2" color="inherit" fontWeight="bold">
                  ${quote.totalValue.toFixed(2)}
                </Typography>
                <Typography variant="caption" noWrap>
                  {new Date(quote.acceptedTime).toLocaleDateString()}
                </Typography>
                {!quote.location && (
                  <Typography color="error" variant="caption">
                    Customer location unavailable.
                  </Typography>
                )}
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>

      {/* Maps Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
          Maps
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography>Active Quotes</Typography>
                <Typography variant="caption">Door Hangers</Typography>
                <QuoteMap quotes={preparedActiveQuotes} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography>Accepted Quotes</Typography>
                <Typography variant="caption">Last 7 Days</Typography>
                <QuoteMap quotes={preparedAcceptedQuotes} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Quote Templates */}
      <Box>
        <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
          Quote Templates
        </Typography>
        <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2, mt: 2, pb: 1 }}>
          {quoteTemplates.map((template) => (
            <Card
              key={template.templateId}
              onClick={() => handleTemplateClick(template)}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 3,
                },
                minWidth: 250,
                flex: '0 0 auto',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FileCopy sx={{ fontSize: 40, color: 'warning.main', mb: 1, mt: 1 }} />
                <Typography variant="subtitle2" noWrap>{template.name}</Typography>
                <Typography variant="caption" noWrap>
                  {new Date(template.createdAt).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </>
  );

  // Loading State
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Error State
  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error" variant="h6" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </Box>
    );
  }

  // Main Dashboard Layout
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
     {/* Sidebar */}
<Box
  sx={{
    width: sidebarExpanded ? 220 : 60,
    flexShrink: 0, // Prevent the sidebar from shrinking
    color: 'white',
    p: 2,
    transition: 'width 0.3s, box-shadow 0.3s',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'primary.main',
    boxShadow: sidebarExpanded
      ? '2px 0 10px rgba(0, 0, 0, 0.4)'
      : '2px 0 5px rgba(0, 0, 0, 0.4)',
    zIndex: 1000,
  }}
>
  {/* Logo and Toggle Button */}
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        p: 0.5,
        transition: 'transform 0.3s',
        '&:hover': {
          transform: sidebarExpanded ? 'scale(1)' : 'scale(1.1)',
        },
      }}
      onClick={toggleSidebar}
    >
      <img
        src="/logo192.png"
        alt="CowboyQuote Logo"
        style={{
          width: 30,
          height: 30,
          marginRight: sidebarExpanded ? 5 : 0,
          marginLeft: -5,
          marginTop: 5,
          transition: 'transform 0.3s',
          transform: sidebarExpanded ? 'scale(1)' : 'scale(1.1)',
        }}
      />
      {sidebarExpanded && (
        <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>
          CowboyQuote
        </Typography>
      )}
    </Box>
  </Box>

  {/* Navigation Links */}
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flexGrow: 1 }}>
    {[
      { path: '/dashboard', icon: <Home />, label: 'Home' },
      { path: '/dashboard/quotes', icon: <Description />, label: 'Quotes' },
      { path: '/dashboard/predefined-services', icon: <Build />, label: 'Services' },
      { path: '/dashboard/templates', icon: <FileCopy />, label: 'Templates' },
      { path: '/dashboard/customers', icon: <Bungalow />, label: 'Customers' },
      { path: '/dashboard/settings', icon: <SettingsIcon />, label: 'Settings' },
    ].map((item) => (
      <Link key={item.path} to={item.path} style={{ textDecoration: 'none', color: 'white' }}>
        <Tooltip title={!sidebarExpanded ? item.label : ''} placement="right" arrow>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: sidebarExpanded ? 'flex-start' : 'center',
              gap: 2,
              px: sidebarExpanded ? 1 : 0,
              minHeight: 40,
              transition: 'all 0.2s ease',
              '&:hover': {
                '& .MuiSvgIcon-root': {
                  color: 'warning.main',
                  transform: 'scale(1.1)',
                },
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& .MuiSvgIcon-root': {
                  transition: 'all 0.2s ease',
                  fontSize: 24,
                },
              }}
            >
              {item.icon}
            </Box>
            {sidebarExpanded && (
              <Typography
                sx={{
                  transition: 'opacity 0.2s ease',
                  whiteSpace: 'nowrap',
                }}
              >
                {item.label}
              </Typography>
            )}
          </Box>
        </Tooltip>
      </Link>
    ))}
  </Box>

  {/* Logout Button */}
  <Link to="/logout" style={{ textDecoration: 'none', color: 'white' }}>
    <Box
      sx={{
        mt: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: sidebarExpanded ? 'flex-start' : 'center',
        width: '100%',
        pb: 1,
        borderTop: 0,
        borderColor: 'rgba(255, 255, 255, 0.12)',
      }}
    >
      <IconButton
        color="inherit"
        sx={{
          width: 40,
          height: 40,
          mr: sidebarExpanded ? 1 : 0,
        }}
      >
        <LogoutIcon />
      </IconButton>
      {sidebarExpanded && (
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Logout
        </Typography>
      )}
    </Box>
  </Link>

  {/* Night Mode Toggle */}
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: sidebarExpanded ? 'flex-start' : 'center',
      width: '100%',
      pt: 2,
      borderTop: 1,
      borderColor: 'rgba(255, 255, 255, 0.12)',
    }}
  >
    <IconButton
      onClick={colorMode.toggleColorMode}
      color="inherit"
      sx={{
        width: 40,
        height: 40,
        mr: sidebarExpanded ? 1 : 0,
      }}
    >
      {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
    {sidebarExpanded && (
      <Typography variant="body2" sx={{ flexGrow: 1 }}>
        {theme.palette.mode === 'dark' ? 'Light Mode' : 'Dark Mode'}
      </Typography>
    )}
  </Box>
</Box>

      {/* Main Content Area */}
      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: 'background.default', overflowY: 'auto' }}>
        <CreateNew />
        <Routes>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/quotes" element={<Quotes />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/customers" element={<Customers />}/>
          <Route path="/settings" element={<Settings />} />
          <Route path="/subscription" element={<SubscriptionManager />} />
          <Route path="/all-quotes" element={<ViewAllQuotes />} />
          <Route path="/predefined-services" element={<PredefinedServicesPage />} />
          <Route path="/internal/quote/:quoteId" element={<ViewQuote />} />
        </Routes>
        <TermsOfServiceModal
          onAcceptComplete={handleTosAcceptComplete}
        />
        {tosAccepted && (
          <WelcomeModal
            open={showWelcomeModal}
            onLocationSet={handleLocationSet}
          />
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;