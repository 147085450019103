// predefinedservices.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles'; // Import useTheme

interface Service {
  _id: string;
  name: string;
  description: string;
  quotePageDesc: string;
  fineText: string;
}

// Define the Quill modules and formats
const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = ['bold', 'italic', 'underline', 'list', 'bullet'];

const PredefinedServices: React.FC = () => {
  const theme = useTheme(); // Access the current theme
  const [services, setServices] = useState<Service[]>([]);
  const [open, setOpen] = useState(false);
  const [editingService, setEditingService] = useState<Service | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/api/predefinedServices');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching predefined services:', error);
      enqueueSnackbar('Failed to fetch predefined services', { variant: 'error' });
    }
  };

  const handleOpen = (service: Service | null = null) => {
    setEditingService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setEditingService(null);
    setOpen(false);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const serviceData = {
      name: formData.get('name') as string,
      description: formData.get('description') as string,
      quotePageDesc: editingService?.quotePageDesc || '', // Ensure it's captured
      fineText: formData.get('fineText') as string,
    };

    try {
      if (editingService) {
        await axiosInstance.put(`/api/predefinedServices/${editingService._id}`, serviceData);
        enqueueSnackbar('Service updated successfully', { variant: 'success' });
      } else {
        await axiosInstance.post('/api/predefinedServices', serviceData);
        enqueueSnackbar('Service created successfully', { variant: 'success' });
      }
      fetchServices();
      handleClose();
    } catch (error) {
      console.error('Error saving predefined service:', error);
      enqueueSnackbar('Failed to save service', { variant: 'error' });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axiosInstance.delete(`/api/predefinedServices/${id}`);
      enqueueSnackbar('Service deleted successfully', { variant: 'success' });
      fetchServices();
    } catch (error) {
      console.error('Error deleting predefined service:', error);
      enqueueSnackbar('Failed to delete service', { variant: 'error' });
    }
  };

  // Updated handleServiceChange without 'index'
  const handleServiceChange = (field: keyof Service, value: string) => {
    if (editingService) {
      setEditingService({
        ...editingService,
        [field]: field === 'quotePageDesc' ? value.trim() : value,
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
      <Box sx={{ width: '100%', p: 2, overflowY: 'auto' }}>
        <Typography variant="h6" gutterBottom>
          Predefined Services
        </Typography>
        <Button
          startIcon={<Add />}
          onClick={() => handleOpen()}
          variant="contained"
          color="warning"
        >
          Add New Service
        </Button>
        <List>
          {services.map((service) => (
            <ListItem key={service._id}>
              <ListItemText primary={service.name} secondary={service.description} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => handleOpen(service)}>
                  <Edit />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(service._id)}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>{editingService ? 'Edit Service' : 'Add New Service'}</DialogTitle>
          <form onSubmit={handleSave}>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Service Name"
                type="text"
                fullWidth
                defaultValue={editingService?.name || ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'warning.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'warning.main',
                  },
                }}
              />
              <TextField
                margin="dense"
                name="description"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                defaultValue={editingService?.description || ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'warning.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'warning.main',
                  },
                }}
              />
              {/* Styled ReactQuill Component */}
              <Box
                sx={{
                  mt: 2,
                  mb: 4,
                  '& .ql-toolbar': {
                    backgroundColor: theme.palette.background.default,
                    borderColor: theme.palette.divider,
                    '& button, & .ql-picker': {
                      color: theme.palette.text.primary,
                    },
                    '& button:hover, & .ql-picker:hover': {
                      color: theme.palette.warning.main,
                    },
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  },
                  '& .ql-container': {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.divider,
                    '& .ql-editor': {
                      minHeight: '150px',
                      '& .ql-placeholder': {
                        color: theme.palette.text.disabled,
                      },
                    },
                  },
                  '& .ql-toolbar, & .ql-container': {
                    borderRadius: theme.shape.borderRadius,
                  },
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Quote Page Description:
                </Typography>
                <ReactQuill
                  value={editingService?.quotePageDesc || ''}
                  onChange={(content: string) => handleServiceChange('quotePageDesc', content)}
                  modules={quillModules}
                  formats={quillFormats}
                />
              </Box>
              <TextField
                margin="dense"
                name="fineText"
                label="Fine Text"
                type="text"
                fullWidth
                multiline
                rows={3}
                defaultValue={editingService?.fineText || ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'warning.main',
                    },
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'warning.main',
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ color: 'info', bgcolor: 'info.main' }}
              >
                Cancel
              </Button>
              <Button type="submit" color="warning" variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default PredefinedServices;