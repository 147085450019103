import React from 'react';
import { Box, Typography } from '@mui/material';
import PredefinedServices from './PredefinedServices';

const PredefinedServicesPage: React.FC = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom align='center'>Manage Predefined Services</Typography>
      <PredefinedServices />
    </Box>
  );
};

export default PredefinedServicesPage;