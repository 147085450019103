// src/components/ViewQuote.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Paper,
  Grid,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import axiosInstance from '../utils/axiosConfig';
import { generateQuotePDF } from '../utils/pdfGenerator';
import 'leaflet/dist/leaflet.css';
import { ArrowBack, DownloadOutlined, ContentCopy } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles'; // Import useTheme

// Import marker icons
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Define the icon for markers
const defaultIcon = new Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// **Define Interfaces Within the Component**

interface Service {
  id: string;
  name: string;
  description: string;
  fineText?: string;
}

interface QuoteTemplate {
  templateId: string;
  name: string;
  introParagraph: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  companyName: string;
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
  };
  landingPageIntro?: string;
  daysToExpire: number;
}

interface Quote {
  quoteId: string;
  templateId: string;
  templateName: string;
  services: string[];
  serviceValues: string[];
  location?: {
    latitude: number;
    longitude: number;
  };
  activatedTime: string;
  acceptedTime: string;
  totalValue: number;
  status: 'Idle' | 'Active' | 'Accepted' | 'Rejected';
}

interface CustomerData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature?: string;
  addressLocation?: {
    latitude: number;
    longitude: number;
  };
}

interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string;
}

const ViewQuote: React.FC = () => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const [quote, setQuote] = useState<Quote | null>(null);
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [companySettings, setCompanySettings] = useState<CompanySettings | null>(null);
  const [template, setTemplate] = useState<QuoteTemplate | null>(null);
  const [predefinedServices, setPredefinedServices] = useState<Service[]>([]); // State to store predefined services
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme(); // Access the current theme

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch Quote and Company Settings
        const [quoteResponse, companySettingsResponse] = await Promise.all([
          axiosInstance.get(`/api/quotes/${quoteId}`),
          axiosInstance.get('/api/compSettings'),
        ]);

        setQuote(quoteResponse.data);
        setCompanySettings(companySettingsResponse.data);

        // Fetch Customer Data if Quote is Accepted
        if (quoteResponse.data.status === 'Accepted') {
          const customerResponse = await axiosInstance.get(
            `/api/customers/customerByQuote/${quoteId}?companyName=${encodeURIComponent(companySettingsResponse.data.companyName)}`
          );
          setCustomerData(customerResponse.data);
        }

        // Fetch Quote Template
        const templateResponse = await axiosInstance.get(`/api/quoteTemplates/${quoteResponse.data.templateId}`);
        const fetchedTemplate: QuoteTemplate = templateResponse.data;
        setTemplate(fetchedTemplate);

        // Fetch Predefined Services if any
        if (fetchedTemplate.predefinedServiceIds.length > 0) {
          const predefinedRes = await axiosInstance.get('/api/predefinedServices');
          setPredefinedServices(predefinedRes.data);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load quote and customer data. Please try again.');
        setLoading(false);
      }
    };

    fetchData();
  }, [quoteId, theme.palette.mode]); // Added theme.palette.mode as a dependency in case theme changes

  const handleBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  const handleDownloadPDF = async () => {
    if (!quote || !customerData || !companySettings || !template) {
      enqueueSnackbar('Unable to generate PDF. Missing quote, template, customer, or company data.', { variant: 'error' });
      return;
    }

    try {
       // Determine the viewport ratio
       const viewportRatio = window.devicePixelRatio || 1;

      const companyLogoUrl = companySettings.companyLogo
        ? `${process.env.REACT_APP_API_URL}${companySettings.companyLogo.replace(/^\//, '')}`
        : undefined;

      // Pass predefinedServices to generateQuotePDF
      const pdfBlob = await generateQuotePDF(quote, template, customerData, viewportRatio, companyLogoUrl, predefinedServices);

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quote_${quote.quoteId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      enqueueSnackbar('PDF downloaded successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error generating PDF:', error);
      enqueueSnackbar('Failed to generate PDF. Please try again.', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!quote || !companySettings || !template) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography>Quote, template, or company settings not found.</Typography>
      </Box>
    );
  }

  // Determine displayLocation based on your requirements
  let displayLocation: { latitude: number; longitude: number } | null = null;

  if (quote.status === 'Accepted') {
    if (customerData && customerData.addressLocation) {
      displayLocation = customerData.addressLocation;
    } else if (quote.location) {
      displayLocation = quote.location;
    }
  } else {
    if (quote.location) {
      displayLocation = quote.location;
    }
  }

  const generateConcatenatedId = (quoteId: string): string => {
    const parts = quoteId.split('_');
    if (parts.length < 4) return 'Invalid ID';
    const userId = parts[2]?.slice(-2) || '00';
    const templateId = parts[3]?.slice(-2) || '00';
    const quoteNumber = parts[parts.length - 1] || '0000';
    return `${userId}${templateId}${quoteNumber}`;
  };

  // **CopyableTypography Component**
  interface CopyableTypographyProps {
    label: string;
    value: string;
  }

  const CopyableTypography: React.FC<CopyableTypographyProps> = ({ label, value }) => {
    const handleClick = () => {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          enqueueSnackbar(`${label} copied to clipboard`, { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar(`Failed to copy ${label}`, { variant: 'error' });
        });
    };

    return (
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          userSelect: 'text',
          '&:hover': {
            backgroundColor: '#f5f5f5',
            color: '#052F46'
          },
          padding: '4px 8px',
          borderRadius: '4px',
          marginTop: '4px',
        }}
        title="Click to copy"
      >
        <Typography>
          <strong>{label}:</strong> {value}
        </Typography>
        <Tooltip title="Copy to clipboard">
          <ContentCopy fontSize="small" sx={{ ml: 1 }} />
        </Tooltip>
      </Box>
    );
  };

  // **Start of Dark Mode Implementation**

  // Define tile layer URLs and attribution based on theme
  const tileLayerConfig = {
    light: {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>',
    },
    dark: {
      url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
      attribution:
        '&copy; <a href="https://carto.com/attributions">CARTO</a> | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>',
    },
  };

  const selectedTileLayer = theme.palette.mode === 'dark' ? tileLayerConfig.dark : tileLayerConfig.light;

  // Optional: Adjust marker icons based on theme for better visibility
  const getCustomIcon = () => {
    // You can provide different icons for dark mode if needed
    const iconUrl =
      theme.palette.mode === 'dark'
        ? 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png' // Replace with a dark-friendly icon if available
        : 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png';

    return new Icon({
      iconUrl,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
      shadowSize: [41, 41],
    });
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Back Button */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={12}>
          <IconButton onClick={handleBack} sx={{ mr: 2, top: -20, width: 30, height: 30 }}>
            <ArrowBack />
          </IconButton>
        </Grid>
      </Grid>

      {/* Quote Header */}
      <Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4">{quote.templateName}</Typography>
            <Typography variant="h6">Quote ID: {generateConcatenatedId(quote.quoteId)}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
            <Chip
              label={quote.status}
              color={
                quote.status === 'Accepted'
                  ? 'success'
                  : quote.status === 'Rejected'
                  ? 'error'
                  : quote.status === 'Active'
                  ? 'warning'
                  : 'default'
              }
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Quote Details */}
      <Grid container spacing={3}>
        {/* Left Side: Services and Customer Info */}
        <Grid item xs={12} md={6}>
          {/* Services Table */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: 'primary.main' }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" component="strong" sx={{ fontWeight: 'bold', color: 'white' }}>
                      Service
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1" component="strong" sx={{ fontWeight: 'bold', color: 'white' }}>
                      Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quote.services.map((service, index) => {
                  // Find service details from template or predefinedServices
                  const serviceDetails =
                    template.customServices.find((s) => s.name === service) ||
                    predefinedServices.find((s) => s.name === service);

                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" sx={{ pl: 5 }}>
                        {service}
                      </TableCell>
                      <TableCell align="right"> ${quote.serviceValues[index] || 'N/A'}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell scope="row" sx={{ lineHeight: 1 }}>
                    <Typography variant="subtitle1" component="strong" sx={{ fontWeight: 'bold' }}>
                      Total Value
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1" component="strong" sx={{ fontWeight: 'bold' }}>
                      ${quote.totalValue.toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Activation or Acceptance Time */}
          <Box sx={{ mt: 3 }}>
            <Typography>
              <strong>{quote.status === 'Accepted' ? 'Accepted Time:' : 'Activated Time:'}</strong>{' '}
              {new Date(quote.status === 'Accepted' ? quote.acceptedTime : quote.activatedTime).toLocaleString()}
            </Typography>
            {displayLocation && (
              <Typography>
                <strong>Location:</strong> {displayLocation.latitude.toFixed(6)}, {displayLocation.longitude.toFixed(6)}
              </Typography>
            )}
          </Box>

          {/* Customer Information */}
          {quote.status === 'Accepted' && customerData && (
            <Paper elevation={2} sx={{ mt: 3, p: 2 }}>
              <Typography variant="h6">
                <strong>Customer Information</strong>
              </Typography>
              {/* Hint Text */}
              <Typography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
                (Click on a field to copy)
              </Typography>
              {/* Copyable Fields */}
              <CopyableTypography
                label="Name"
                value={`${customerData.firstName} ${customerData.lastName}`}
              />
              <CopyableTypography label="Email" value={customerData.email} />
              <CopyableTypography label="Phone" value={customerData.phone} />
              <CopyableTypography
                label="Address"
                value={`${customerData.address}, ${customerData.city}, ${customerData.state} ${customerData.zip}`}
              />
            </Paper>
          )}

          {/* Download PDF Button */}
          {quote.status === 'Accepted' && customerData && (
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="warning"
                startIcon={<DownloadOutlined />}
                onClick={handleDownloadPDF}
                fullWidth
              >
                Download PDF
              </Button>
            </Box>
          )}
        </Grid>

        {/* Right Side: Map */}
        <Grid item xs={12} md={6}>
          {displayLocation && (
            <Box sx={{ height: 680, width: '100%' }}>
              <MapContainer
                center={[displayLocation.latitude, displayLocation.longitude]}
                zoom={13}
                style={{ height: '100%', width: '100%' }}
              >
                <TileLayer
                  url={selectedTileLayer.url} // Dynamically set based on theme
                  attribution={selectedTileLayer.attribution} // Dynamically set based on theme
                />
                <Marker position={[displayLocation.latitude, displayLocation.longitude]} icon={defaultIcon}>
                  <Popup>
                    {quote.status === 'Accepted' ? (
                      <>
                        <strong>Customer Name:</strong> {`${customerData?.firstName} ${customerData?.lastName}`}
                        <br />
                        <strong>Accepted Time:</strong> {new Date(quote.acceptedTime).toLocaleString()}
                        <br />
                        <strong>Total Value:</strong> ${quote.totalValue.toFixed(2)}
                      </>
                    ) : (
                      <>
                        <strong>Quote ID:</strong> {generateConcatenatedId(quote.quoteId)}
                        <br />
                        <strong>Activated Time:</strong> {new Date(quote.activatedTime).toLocaleString()}
                        <br />
                        <strong>Total Value:</strong> ${quote.totalValue.toFixed(2)}
                      </>
                    )}
                  </Popup>
                </Marker>
              </MapContainer>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewQuote;