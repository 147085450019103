// src/components/ResendVerification.tsx

import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import CowboyHatLogo from './CowboyHatLogo'; // Import the animated logo
import '../App.css';

const ResendVerification: React.FC = () => {
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/email/resend-verification', {
                email,
                companyName
            });

            if (response.status === 200) {
                setMessage(response.data.message);
                setError('');
            } else {
                setError(response.data.message || 'Failed to resend verification email.');
                setMessage('');
            }
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to resend verification email.');
            setMessage('');
            console.error('Resend Verification error:', err);
        }
    };

    return (
        <div className="resend-verification-container" style={{ textAlign: 'center', padding: '20px' }}>
            <CowboyHatLogo />
            <h2>Resend Verification Email</h2>
            {message && <p className="success" style={{ color: 'green' }}>{message}</p>}
            {error && <p className="error" style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Resend Verification Email</button>
            </form>
        </div>
    );
};

export default ResendVerification;
