// TermsOfServiceModal.tsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import axiosInstance from '../utils/axiosConfig';

const TOS_VERSION = '1.0.0';

interface TermsOfServiceModalProps {
  onAcceptComplete: () => void;
}

export default function TermsOfServiceModal({ onAcceptComplete }: TermsOfServiceModalProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [acceptingTos, setAcceptingTos] = useState(false);

  useEffect(() => {
    checkTosStatus();
  }, []);

  const checkTosStatus = async () => {
    try {
      const response = await axiosInstance.get('/api/tos/status');
      if (!response.data.tosAccepted || response.data.tosAcceptedVersion !== TOS_VERSION) {
        setOpen(true);
      } else {
        // If ToS is already accepted, trigger the callback
        onAcceptComplete();
      }
    } catch (error) {
      console.error('Error checking ToS status:', error);
      setError('Failed to check Terms of Service status');
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    setAcceptingTos(true);
    try {
      await axiosInstance.post('/api/tos/accept', {
        version: TOS_VERSION,
      });
      setOpen(false);
      onAcceptComplete(); // Call the callback first
      
      // Add a slight delay before refreshing to ensure state updates are complete
      setTimeout(() => {
        window.location.reload();
      }, 100);
      
    } catch (error) {
      console.error('Error accepting ToS:', error);
      setError('Failed to accept Terms of Service');
    } finally {
      setAcceptingTos(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      disableEscapeKeyDown
      onClose={(event, reason) => {
        // Prevent closing by backdrop click
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h6">Terms of Service</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Version {TOS_VERSION} - Effective Date: October 27, 2024
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto', pr: 2 }}>
          {/* Introduction */}
          <Typography variant="body1" paragraph>
            Welcome to CowboyQuote LLC (“Company”, “we”, “our”, “us”). These Terms of Service (“Terms”, “Terms of Service”) govern your access to and use of our services, including any content, functionality, and services offered on our website or through our applications (collectively, the “Service”).
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing or using the Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Service.
          </Typography>

          {/* 1. Acceptance of Terms */}
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By creating an account, subscribing to a plan, or otherwise using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms, including our Privacy Policy.
          </Typography>
          <Typography variant="body1" paragraph>
            Each time these Terms are updated, the Effective Date will change, and users will be notified to accept the new version to continue using the Service. We maintain a record of each user’s acceptance of these Terms, including the version accepted and the timestamp of acceptance.
          </Typography>

          {/* 2. Changes to Terms */}
          <Typography variant="h6" gutterBottom>
            2. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </Typography>
          <Typography variant="body1" paragraph>
            Your continued use of the Service following the posting of revised Terms means that you accept and agree to the changes.
          </Typography>

          {/* 3. Description of Service */}
          <Typography variant="h6" gutterBottom>
            3. Description of Service
          </Typography>
          <Typography variant="body1" paragraph>
            CowboyQuote LLC provides a subscription-based quotation management platform that allows users to create, manage, and send quotes to clients efficiently and securely.
          </Typography>

          {/* 4. User Responsibilities */}
          <Typography variant="h6" gutterBottom>
            4. User Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Account Security:</strong> You are responsible for safeguarding your account credentials. Notify us immediately of any unauthorized use of your account.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Compliance:</strong> You agree to comply with all applicable laws, regulations, and these Terms when using the Service.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Prohibited Activities:</strong> You must not use the Service for any unlawful or prohibited activities, including but not limited to:
          </Typography>
          <ul>
            <li>Infringing on intellectual property rights.</li>
            <li>Distributing malicious software.</li>
            <li>Engaging in fraudulent activities.</li>
          </ul>

          {/* 5. Payment and Subscription */}
          <Typography variant="h6" gutterBottom>
            5. Payment and Subscription
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Subscription Plans:</strong> We offer various subscription plans with different features and pricing. Details are available on our Pricing Page.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Billing:</strong> Payments are processed securely through Stripe. By subscribing, you authorize us to charge your selected payment method.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Proration Policy:</strong> To ensure a straightforward billing experience, we have disabled proration to prevent refunds for unused subscription time, but the quote credits are still available through the end of the billing period. This means that when you change or cancel your subscription, no refunds are issued.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Cancellation:</strong> You may cancel your subscription at any time. Cancellations take effect at the end of the current billing period. No refunds will be issued for unused time, but the quote credits will remain available through the end of the billing cycle.
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Stripe’s Best Practices for Payment Handling:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Proration Behavior:</strong> We have configured our subscription updates to disable proration, ensuring that no refunds are issued for unused subscription time.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>• Secure Payment Processing:</strong> All payment information is processed securely through Stripe, adhering to PCI DSS standards.
          </Typography>

          {/* 6. Privacy Policy */}
          <Typography variant="h6" gutterBottom>
            6. Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Your use of the Service is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal data.
          </Typography>

          {/* 7. Termination */}
          <Typography variant="h6" gutterBottom>
            7. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason, including if you breach these Terms.
          </Typography>
          <Typography variant="body1" paragraph>
            Upon termination, your right to use the Service will cease immediately, and any provisions that should survive termination will remain in effect.
          </Typography>

          {/* 8. Intellectual Property */}
          <Typography variant="h6" gutterBottom>
            8. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are the exclusive property of CowboyQuote LLC and are protected by international intellectual property laws.
          </Typography>

          {/* 9. Limitation of Liability */}
          <Typography variant="h6" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            In no event shall CowboyQuote LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
          </Typography>
          <ul>
            <li>Your access to or use of or inability to access or use the Service.</li>
            <li>Any conduct or content of any third party on the Service.</li>
            <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
          </ul>

          {/* 10. Disclaimer of Warranties */}
          <Typography variant="h6" gutterBottom>
            10. Disclaimer of Warranties
          </Typography>
          <Typography variant="body1" paragraph>
            Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied.
          </Typography>

          {/* 11. Governing Law */}
          <Typography variant="h6" gutterBottom>
            11. Governing Law
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms shall be governed and construed in accordance with the laws of the State of [Your State], without regard to its conflict of law provisions.
          </Typography>

          {/* 12. Contact Us */}
          <Typography variant="h6" gutterBottom>
            12. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about these Terms, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            CowboyQuote LLC<br />
            <a href="mailto:contact@cowboyquote.com" style={{color:'white'}}>contact@cowboyquote.com</a>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAccept}
          variant="contained"
          color="warning"
          disabled={acceptingTos}
        >
          {acceptingTos ? 'Accepting...' : 'I Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}