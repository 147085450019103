// src/components/ResetPassword.tsx

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import CowboyHatLogo from './CowboyHatLogo'; // Ensure this path is correct
import '../App.css'; // Ensure this path is correct based on your project structure

const ResetPassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tokenParam = params.get('token');
        setToken(tokenParam);
    }, [location]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Clear previous messages
        setError('');
        setMessage('');

        // Frontend Validations
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        if (newPassword.length < 6) {
            setError('Password must be at least 6 characters long.');
            return;
        }

        if (!token) {
            setError('Invalid or missing token.');
            return;
        }

        setIsLoading(true); // Start loading

        try {
            const response = await axiosInstance.post('/password/reset-password', {
                token,
                newPassword
            });

            if (response.status === 200) {
                setMessage(response.data.message);
                setError('');
                // Optionally, redirect to login after a delay
                setTimeout(() => {
                    navigate('/login');
                }, 5000); // Redirects after 5 seconds
            } else {
                setError(response.data.message || 'Failed to reset password.');
                setMessage('');
            }
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to reset password.');
            setMessage('');
            console.error('Reset Password error:', err);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const handleResetForm = () => {
        setNewPassword('');
        setConfirmPassword('');
        setError('');
        setMessage('');
    };

    return (
        <div className="auth-container reset-password-container">
            <CowboyHatLogo /> {/* Clickable logo redirects to home */}
            <h2>Reset Your Password</h2>

            {message && (
                <div>
                    <p className="success">{message}</p>
                    <button 
                        onClick={() => navigate('/login')} 
                        className="submit-button"
                    >
                        Proceed to Login
                    </button>
                </div>
            )}

            {error && (
                <div className="error-container">
                    <p className="error">{error}</p>
                    <button 
                        onClick={handleResetForm} 
                        className="reset-button"
                    >
                        Reset Form
                    </button>
                </div>
            )}

            {!message && (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="newPassword">New Password:</label>
                        <input
                            type="password"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            placeholder="Enter your new password"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm New Password:</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            placeholder="Confirm your new password"
                        />
                    </div>
                    <button type="submit" className="submit-button" disabled={isLoading}>
                        {isLoading ? 'Resetting Password...' : 'Reset Password'}
                    </button>
                </form>
            )}
        </div>
    );
};

export default ResetPassword;
