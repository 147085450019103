// frontend/components/SubscriptionManager.tsx
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import axiosInstance from '../utils/axiosConfig';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { useRemainingQuotes } from '../RemainingQuotesContext'; // Import the context hook

const stripePromise: Promise<Stripe | null> = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY!
);

interface Plan {
  _id: string;
  name: string;
  quoteLimit: number;
  stripePriceId?: string; // 'Free' plan may not have this
}

interface CompanySubscription {
  planTier: Plan;
  subscriptionStatus: string;
  subscriptionId?: string; // Added this line
  usage: {
    quotesGenerated: number;
    month: number;
  };
  currentPeriodEnd: Date;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

// Define the Free plan outside the component to prevent re-creation on every render
const freePlan: Plan = { _id: 'free', name: 'Free', quoteLimit: 15, stripePriceId: undefined };

const SubscriptionManager: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [subscription, setSubscription] = useState<CompanySubscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({ open: false, message: '', severity: 'success' });
  const [unsubscribeDialogOpen, setUnsubscribeDialogOpen] = useState(false); // State for dialog
  const [processingUnsubscribe, setProcessingUnsubscribe] = useState(false); // State for processing

  const location = useLocation(); // Get current location
  const { refreshRemainingQuotes } = useRemainingQuotes(); // Destructure refresh function from context

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [plansRes, subscriptionRes] = await Promise.all([
          axiosInstance.get('/api/plans'),
          axiosInstance.get('/api/subscription'), // Ensure correct route
        ]);
        const fetchedPlans: Plan[] = plansRes.data;

        // Define the Free plan
        const freePlan: Plan = { _id: 'free', name: 'Free', quoteLimit: 15, stripePriceId: undefined };

        // Combine Free plan with fetched plans
        const combinedPlans: Plan[] = [freePlan, ...fetchedPlans];

        // Define the desired order
        const desiredPlanOrder = ['Free', 'Pro', 'Cowboy'];

        // Sort the combined plans based on desired order
        combinedPlans.sort((a, b) => {
          return desiredPlanOrder.indexOf(a.name) - desiredPlanOrder.indexOf(b.name);
        });

        setPlans(combinedPlans);
        setSubscription(subscriptionRes.data);

        // Refresh remaining quotes after fetching subscription data
        await refreshRemainingQuotes();
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setSnackbar({ open: true, message: 'Failed to load subscription data.', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [refreshRemainingQuotes]);

  useEffect(() => {
    // Check if redirected from Stripe with session_id
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    if (sessionId) {
      // Optionally, verify the session status with your backend
      // For simplicity, refresh the subscription data
      const refreshData = async () => {
        try {
          const subscriptionRes = await axiosInstance.get('/api/subscription');
          setSubscription(subscriptionRes.data);
          setSnackbar({ open: true, message: 'Subscription updated successfully!', severity: 'success' });

          // Refresh remaining quotes after subscription update
          await refreshRemainingQuotes();
        } catch (error) {
          console.error('Error refreshing subscription data:', error);
          setSnackbar({ open: true, message: 'Failed to update subscription.', severity: 'error' });
        }
      };
      refreshData();
    }
  }, [location.search, refreshRemainingQuotes]);

  const handleSubscribe = async (planId: string) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/api/subscription/create-checkout-session', { planId });

      // Destructure the response data
      const { sessionId, message } = response.data;

      if (sessionId) {
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({ sessionId });
        } else {
          console.error("Stripe hasn't loaded correctly");
          setSnackbar({ open: true, message: "Stripe didn't load correctly.", severity: 'error' });
        }
      } else if (message) {
        // Handle the message response
        setSnackbar({ open: true, message: message, severity: 'success' });

        // Refresh subscription and remaining quotes data
        const subscriptionRes = await axiosInstance.get('/api/subscription');
        setSubscription(subscriptionRes.data);
        await refreshRemainingQuotes();
      } else {
        // Unexpected response
        console.error('Unexpected response:', response.data);
        setSnackbar({ open: true, message: 'Unexpected response from server.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setSnackbar({ open: true, message: 'Failed to initiate subscription.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    try {
      setProcessingUnsubscribe(true);
      const response = await axiosInstance.post('/api/subscription/unsubscribe');
      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Unsubscription successful!', severity: 'success' });
        setSubscription(null); // Update the subscription state

        // Refresh remaining quotes after unsubscription
        await refreshRemainingQuotes();
      } else {
        setSnackbar({ open: true, message: 'Failed to unsubscribe.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      setSnackbar({ open: true, message: 'Failed to unsubscribe.', severity: 'error' });
    } finally {
      setProcessingUnsubscribe(false);
      setUnsubscribeDialogOpen(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOpenUnsubscribeDialog = () => {
    setUnsubscribeDialogOpen(true);
  };

  const handleCloseUnsubscribeDialog = () => {
    setUnsubscribeDialogOpen(false);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <CircularProgress />
      </Box>
    );
  }

  const currentPlan = (subscription && subscription.subscriptionStatus === 'Active') 
    ? subscription.planTier 
    : plans.find(p => p.name === 'Free');

  const oldPlan = subscription ? subscription.planTier : plans.find(p => p.name === 'Free');
  const quotesRemaining = oldPlan ? oldPlan.quoteLimit - (subscription?.usage.quotesGenerated || 0) : 0;
  const percentageUsed = oldPlan && oldPlan.quoteLimit > 0 ? ((subscription?.usage.quotesGenerated || 0) / oldPlan.quoteLimit) * 100 : 0;

  // Helper function to get expiration message
  const getExpirationMessage = () => {
    if (oldPlan?.name === 'Free') {
      return 'No expiration for free plan';
    }

    if (subscription?.subscriptionStatus === 'Cancelled' && subscription?.subscriptionId) {
      return `${currentPlan?.name} expires on ${new Date(subscription.currentPeriodEnd).toLocaleDateString()}`;
    }

    if (subscription?.currentPeriodEnd) {
      return `Current Period End: ${new Date(subscription.currentPeriodEnd).toLocaleDateString()}`;
    }

 /*    return 'No expiration for free plan'; */
  };

  const getFeatures = (planName: string) => {
    switch (planName) {
      case 'Free':
        return ['15 quotes per month', 'Basic support'];
      case 'Pro':
        const proQuotePrice = ((22.50 / 150)*100).toFixed(0);
        return ['150 quotes per month', `${proQuotePrice} Cents Per Quote`, 'Advanced Support'];
      case 'Cowboy':
        const cowboyQuotePrice = ((54 / 450)*100).toFixed(0);
        return ['450 quotes per month', `${cowboyQuotePrice} Cents Per Quote`, 'Advanced Support', 'Video Greetings'];
      default:
        return [];
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" gutterBottom align="center" sx={{mb:4}}>
        Subscription Manager
      </Typography>

      {oldPlan && (
        <Paper sx={{ p: 3, mb: 4, backgroundColor: 'primary' }}>
          <Typography variant="h6" gutterBottom>
            Current Plan: <strong>{oldPlan.name}</strong>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={percentageUsed}
                sx={{ height: 10, borderRadius: 5 }}
                color='warning'
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(percentageUsed)}%`}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {quotesRemaining} / {oldPlan.quoteLimit} quotes remaining this month
          </Typography>
          <Typography variant="body2" sx={{ mb: 1, mt: 2 }}>
            {getExpirationMessage()}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Subscription Status:{' '}
            <Chip
              label={subscription ? subscription.subscriptionStatus : 'Inactive'}
              color={
                subscription?.subscriptionStatus === 'Active'
                  ? 'success'
                  : subscription?.subscriptionStatus === 'Cancelled'
                    ? 'warning'
                    : 'default'
              }
            />
          </Typography>

          {/* Optional: Display an alert for cancelled subscriptions */}
          {subscription?.subscriptionStatus === 'Cancelled' && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Your subscription has been cancelled and will expire on {new Date(subscription.currentPeriodEnd).toLocaleDateString()}.
            </Alert>
          )}

          {subscription?.subscriptionStatus === 'Active' && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleOpenUnsubscribeDialog}
            >
              Unsubscribe
            </Button>
          )}
        </Paper>
      )}

      <Grid container spacing={4}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan._id}>
            <StyledPaper elevation={3}>
              <Typography variant="h5" component="h2" gutterBottom>
                {plan.name}
              </Typography>
              <Typography variant="h4" component="div" sx={{ my: 2 }}>
                {plan.name === 'Free' ? '$0.00' : `$${plan.name === 'Pro' ? '22.50' : '54'}/month`}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {plan.quoteLimit} quotes per month
              </Typography>
              <Box sx={{ mt: 2, mb: 2 }}>
                {getFeatures(plan.name).map((feature, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
                    <CheckIcon color="warning" sx={{ mr: 1 }} />
                    <Typography variant="body2">{feature}</Typography>
                  </Box>
                ))}
              </Box>
              {plan.name !== 'Free' && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleSubscribe(plan._id)}
                  disabled={currentPlan?.name === plan.name}
                  fullWidth
                >
                  {currentPlan?.name === plan.name ? 'Current Plan' : 'Select Plan'}
                </Button>
              )}
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" gutterBottom>
          Compare Plans
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'primary.main' }}>
                <TableCell sx={{ color: 'primary.contrastText' }}>Plan</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Quotes / Month</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Price</TableCell>
                <TableCell align="center" sx={{ color: 'primary.contrastText' }}>Video Greetings</TableCell>
                <TableCell align="right" sx={{ color: 'primary.contrastText' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan) => (
                <TableRow key={plan._id} hover>
                  <TableCell component="th" scope="row">
                    {plan.name}
                  </TableCell>
                  <TableCell align="center">{plan.quoteLimit}</TableCell>
                  <TableCell align="center">{plan.name === 'Free' ? 'Free' : `$${plan.name === 'Pro' ? '22.50' : '54.00'}`}</TableCell>
                  <TableCell align="center">{plan.name === 'Free' ? '-' : `${plan.name === 'Pro' ? '-' : 'Yes!'}`}</TableCell>
                  <TableCell align="right">
                    <Chip
                      label={currentPlan?.name === plan.name ? 'Current' : 'Available'}
                      color={currentPlan?.name === plan.name ? 'warning' : 'default'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Unsubscribe Confirmation Dialog */}
      <Dialog
        open={unsubscribeDialogOpen}
        onClose={handleCloseUnsubscribeDialog}
      >
        <DialogTitle>Confirm Unsubscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unsubscribe from your current plan? You will lose access to premium features.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUnsubscribeDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnsubscribe} color="error" disabled={processingUnsubscribe}>
            {processingUnsubscribe ? <CircularProgress size={24} /> : 'Unsubscribe'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriptionManager;