// src/components/SignUp.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import '../App.css';
import CowboyHatLogo from './CowboyHatLogo'; // Ensure this path is correct

const SignUp: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/signup', {
                name,
                email,
                password,
                companyName
            });

            if (response.data.success) {
                setSuccessMessage(response.data.message);
                setError('');
                // Clear the form
                setName('');
                setEmail('');
                setPassword('');
                setCompanyName('');
                
                // Optional: Redirect to login page after a delay
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            } else {
                setError(response.data.message || 'An error occurred during signup.');
                setSuccessMessage('');
            }
        } catch (err: any) {
            setError(err.response?.data?.message || 'Signup failed. Please try again.');
            setSuccessMessage('');
            console.error('Signup error:', err);
        }
    };

    return (
        <div className="auth-container signup-container">
            <CowboyHatLogo /> {/* Clickable logo redirects to home */}
            <h2>Sign Up</h2>
            {error && <p className="error">{error}</p>}
            {successMessage && (
                <div>
                    <p className="success">{successMessage}</p>
                    <p>Please check your email to verify your account.</p>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        placeholder="Enter your name"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Enter your email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Enter your password"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                        placeholder="Enter your company name"
                    />
                </div>
                <button type="submit" className="submit-button">Sign Up</button>
            </form>
            <p>
                <a href="/login">Already have an account? Login here.</a>
            </p>
        </div>
    );
};

export default SignUp;
