// utils/customerExport.ts

interface Customer {
    _id: string;
    quoteId: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    phone: string;
    acceptedAt: string;
  }
  
  /**
   * Converts customer data to CSV format
   * @param customers Array of customer objects
   * @returns CSV string with headers
   */
  export const convertToCSV = (customers: Customer[]) => {
    // Define CSV headers
    const headers = [
      'First Name',
      'Last Name',
      'Address',
      'City',
      'State',
      'ZIP',
      'Email',
      'Phone',
      'Quote ID',
      'Accepted Date'
    ].join(',');
  
    // Convert each customer to CSV row
    const rows = customers.map(customer => {
      // Escape fields that might contain commas
      const escapeCsvField = (field: string) => {
        if (field.includes(',') || field.includes('"') || field.includes('\n')) {
          return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
      };
  
      return [
        escapeCsvField(customer.firstName),
        escapeCsvField(customer.lastName),
        escapeCsvField(customer.address),
        escapeCsvField(customer.city),
        escapeCsvField(customer.state),
        escapeCsvField(customer.zip),
        escapeCsvField(customer.email),
        escapeCsvField(customer.phone),
        escapeCsvField(customer.quoteId),
        new Date(customer.acceptedAt).toLocaleDateString()
      ].join(',');
    });
  
    // Combine headers and rows
    return `${headers}\n${rows.join('\n')}`;
  };
  
  /**
   * Downloads customer data as CSV file
   * @param customers Array of customer objects
   * @param filename Optional filename (defaults to 'customers.csv')
   */
  export const downloadCustomersCSV = (customers: Customer[], filename = 'customers.csv') => {
    const csv = convertToCSV(customers);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };