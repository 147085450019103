import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Checkbox, CircularProgress, IconButton, TableSortLabel, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Button
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FilterList } from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { useSnackbar } from 'notistack';
import dayjs, { Dayjs } from 'dayjs';
import CustomDropdown from './CustomDropdown';
import { useNavigate } from 'react-router-dom';
import { downloadCustomersCSV } from '../utils/customerExport';

interface Customer {
  _id: string;
  quoteId: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  acceptedAt: string;
}

interface Quote {
  quoteId: string;
  status: string;
  acceptedTime: string;
}

interface UserData {
  userId: string;
  name: string;
  email: string;
  companyId: string;
  companyName: string;
  role: string;
}

type SortColumn = 'name' | 'address' | 'email' | 'phone' | 'acceptedAt';
type SortDirection = 'asc' | 'desc';

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [actionValue, setActionValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState<{ column: SortColumn; direction: SortDirection }>({
    column: 'name',
    direction: 'asc'
  });

  // State for Delete Confirmation Dialog
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const fetchCustomers = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const userDataString = localStorage.getItem('userData');
      if (!userDataString) {
        throw new Error('User data not found');
      }
      const userData: UserData = JSON.parse(userDataString);

      // First, fetch accepted quotes
      const quoteParams: any = {
        status: 'Accepted'
      };
      if (startDate) quoteParams.startDate = startDate.format('YYYY-MM-DD');
      if (endDate) quoteParams.endDate = endDate.format('YYYY-MM-DD');

      const quotesResponse = await axiosInstance.get(`/api/quotes/all/${userData.userId}`, { params: quoteParams });
      const acceptedQuotes: Quote[] = quotesResponse.data;

      if (acceptedQuotes.length === 0) {
        setCustomers([]);
        return;
      }

      // Then, fetch customer data for each accepted quote using Promise.allSettled
      const customerPromises = acceptedQuotes.map(quote => 
        axiosInstance.get(`/api/customers/customerByQuote/${quote.quoteId}?companyName=${encodeURIComponent(userData.companyName)}`)
      );

      const customerResults = await Promise.allSettled(customerPromises);

      // Extract fulfilled customer data
      const fetchedCustomers: Customer[] = customerResults
        .filter(result => result.status === 'fulfilled')
        .map((result) => (result as PromiseFulfilledResult<any>).value.data);

      setCustomers(fetchedCustomers);
    } catch (err) {
      console.error('Error fetching customers:', err);
      setError('Failed to load customers. Please try again.');
      enqueueSnackbar('Error fetching customers', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, enqueueSnackbar]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedCustomers(customers.map(c => c._id));
    } else {
      setSelectedCustomers([]);
    }
  };

  const handleSelect = (id: string) => {
    setSelectedCustomers(prev => 
      prev.includes(id) ? prev.filter(customerId => customerId !== id) : [...prev, id]
    );
  };

  const handleDeleteSelected = async () => {
    try {
      const userDataString = localStorage.getItem('userData');
      if (!userDataString) {
        throw new Error('User data not found');
      }
      const userData: UserData = JSON.parse(userDataString);
  
      await axiosInstance.post('/api/customers/deleteMultiple', { 
        customerIds: selectedCustomers,
        companyName: userData.companyName // Include companyName here
      });
      enqueueSnackbar('Selected customers deleted successfully', { variant: 'success' });
      setSelectedCustomers([]);
      fetchCustomers();
    } catch (error) {
      console.error('Error deleting customers:', error);
      enqueueSnackbar('Error deleting customers', { variant: 'error' });
    }
  };

  const handleActionChange = (value: string) => {
    setActionValue(value);
    if (value === 'delete') {
      setIsDeleteDialogOpen(true);
    } else if (value === 'export') {
      const selectedCustomersData = selectedCustomers.length > 0
        ? customers.filter(c => selectedCustomers.includes(c._id))
        : customers;
      
      const filename = `customers_${dayjs().format('YYYY-MM-DD')}.csv`;
      downloadCustomersCSV(selectedCustomersData, filename);
      
      enqueueSnackbar('Customers exported successfully', { variant: 'success' });
    }
    setActionValue('');
  };

  const actionOptions = [
    { value: '', label: 'Select Action' },
    { value: 'delete', label: 'Delete Selected' },
    { value: 'export', label: 'Export to CSV' }
  ];

  const sortCustomers = (customers: Customer[], column: SortColumn, direction: SortDirection) => {
    return [...customers].sort((a, b) => {
      let aValue: string | number;
      let bValue: string | number;

      switch (column) {
        case 'name':
          aValue = `${a.firstName} ${a.lastName}`;
          bValue = `${b.firstName} ${b.lastName}`;
          break;
        case 'address':
          aValue = `${a.address}, ${a.city}, ${a.state} ${a.zip}`;
          bValue = `${b.address}, ${b.city}, ${b.state} ${b.zip}`;
          break;
        case 'email':
          aValue = a.email;
          bValue = b.email;
          break;
        case 'phone':
          aValue = a.phone;
          bValue = b.phone;
          break;
        case 'acceptedAt':
          aValue = new Date(a.acceptedAt).getTime();
          bValue = new Date(b.acceptedAt).getTime();
          break;
        default:
          aValue = '';
          bValue = '';
      }

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (column: SortColumn) => {
    setSortConfig(prevConfig => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedCustomers = sortCustomers(customers, sortConfig.column, sortConfig.direction);

  const handleCustomerClick = (quoteId: string) => {
    navigate(`../internal/quote/${quoteId}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
        <Box sx={{ width: '100%', p: 2, overflowY: 'auto' }}>
      <Typography variant="h4" gutterBottom align='center'>Customers</Typography>
      
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            slotProps={{ textField: { size: 'small', 
              sx: {
                mr: 1,
                // Base styles for the TextField
                '& .MuiOutlinedInput-root': {
                  // Default border color
                  '& fieldset': {
                    borderColor: 'grey.400',
                  },
                  // Border color on hover
                  '&:hover fieldset': {
                    borderColor: 'warning.light',
                  },
                  // Border color when focused
                  '&.Mui-focused fieldset': {
                    borderColor: 'warning.main',
                  },
                },
              },
              // Styling the InputLabel via InputLabelProps
              InputLabelProps: {
                sx: {
                   // Default label color
                  '&.Mui-focused': {
                    color: 'warning.main', // Label color when focused
                  },
                },
              },
            
            },
          }}
        />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            slotProps={{ textField: { size: 'small', 
              sx: {
                mr: 1,
                // Base styles for the TextField
                '& .MuiOutlinedInput-root': {
                  // Default border color
                  '& fieldset': {
                    borderColor: 'grey.400',
                  },
                  // Border color on hover
                  '&:hover fieldset': {
                    borderColor: 'warning.light',
                  },
                  // Border color when focused
                  '&.Mui-focused fieldset': {
                    borderColor: 'warning.main',
                  },
                },
              },
              // Styling the InputLabel via InputLabelProps
              InputLabelProps: {
                sx: {
                   // Default label color
                  '&.Mui-focused': {
                    color: 'warning.main', // Label color when focused
                  },
                },
              },


             } }}
          />
          <IconButton onClick={fetchCustomers} size="small" sx={{width:30}}>
            <FilterList/>
          </IconButton>
        </Box>
        
        <CustomDropdown
          label=""
          value={actionValue}
          onChange={handleActionChange}
          options={actionOptions}
          id="customer-actions"
        />
      </Box>

      {/* Conditionally Render Table or "No Customers" Message */}
      {customers.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedCustomers.length > 0 && selectedCustomers.length < customers.length}
                    checked={selectedCustomers.length === customers.length}
                    onChange={handleSelectAll}
                    sx={{
                      '&.Mui-checked': {
                        color: 'warning.main',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.column === 'name'}
                    direction={sortConfig.column === 'name' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.column === 'address'}
                    direction={sortConfig.column === 'address' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('address')}
                  >
                    Address
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.column === 'email'}
                    direction={sortConfig.column === 'email' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.column === 'phone'}
                    direction={sortConfig.column === 'phone' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('phone')}
                  >
                    Phone
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.column === 'acceptedAt'}
                    direction={sortConfig.column === 'acceptedAt' ? sortConfig.direction : 'asc'}
                    onClick={() => handleSort('acceptedAt')}
                  >
                    Accepted Date
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedCustomers.map((customer) => (
                <TableRow key={customer._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomers.includes(customer._id)}
                      onChange={() => handleSelect(customer._id)}
                      sx={{
                        '&.Mui-checked': {
                          color: 'warning.main',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell 
                    onClick={() => handleCustomerClick(customer.quoteId)}
                    sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                  >
                    {`${customer.firstName} ${customer.lastName}`}
                  </TableCell>
                  <TableCell>{`${customer.address}, ${customer.city}, ${customer.state} ${customer.zip}`}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phone}</TableCell>
                  <TableCell>{new Date(customer.acceptedAt).toLocaleDateString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" align="center">No Customers</Typography>
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="delete-confirmation-dialog-title"
        aria-describedby="delete-confirmation-dialog-description"
      >
        <DialogTitle id="delete-confirmation-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-dialog-description">
            Are you sure you want to delete the selected customers? This action will delete all their quotes and remove their data permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="info" variant='contained' sx={{mr:1}}>
            Cancel
          </Button>
          <Button onClick={() => {
            setIsDeleteDialogOpen(false);
            handleDeleteSelected();
          }} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Box>
    
  );
};

export default Customers;