// utils/auth.ts
import {jwtDecode} from 'jwt-decode';

interface DecodedToken {
  userId: string;
  name: string;
  email: string;
  role: string;
  // Add other fields as per your JWT payload
}

export const getDecodedToken = (): DecodedToken | null => {
  const token = localStorage.getItem('token'); // Adjust the storage key as needed
  if (!token) return null;

  try {
    const decoded: DecodedToken = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.error('Invalid token:', error);
    return null;
  }
};