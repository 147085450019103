import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production'
      ? 'https://cowboy-server-9qyo9.ondigitalocean.app/'
      : 'http://localhost:3000/',
      headers: {
        'Content-Type': 'application/json',
    }
   
  });

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            localStorage.removeItem('token'); // Clear the invalid token
            // Redirect to login page or refresh token logic here
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;