// src/components/ForgotPassword.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import CowboyHatLogo from './CowboyHatLogo'; // Ensure this path is correct
import '../App.css'; // Ensure this path is correct based on your project structure

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/password/forgot-password', {
                email,
                companyName
            });

            if (response.status === 200) {
                setMessage(response.data.message);
                setError('');
                // Optionally, redirect or clear form fields
            } else {
                setError(response.data.message || 'Failed to initiate password reset.');
                setMessage('');
            }
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to initiate password reset.');
            setMessage('');
            console.error('Forgot Password error:', err);
        }
    };

    return (
        <div className="auth-container forgot-password-container">
            <CowboyHatLogo /> {/* Makes the logo clickable, redirects to home */}
            <h2>Forgot Password</h2>
            {message && <p className="success">{message}</p>}
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Registered Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Enter your registered email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                        placeholder="Enter your company name"
                    />
                </div>
                <button type="submit" className="submit-button">Send Password Reset Email</button>
            </form>
            <p>
                <a href="/login">Back to Login</a>
            </p>
        </div>
    );
};

export default ForgotPassword;
