//frontend/src/RemainingQuotesContext.tsx
import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import axiosInstance from './utils/axiosConfig';

interface RemainingQuotesContextProps {
  remainingQuotes: number | null;
  monthlyLimit: number | null;
  refreshRemainingQuotes: () => Promise<void>;
}

export const RemainingQuotesContext = createContext<RemainingQuotesContextProps>({
  remainingQuotes: null,
  monthlyLimit: null,
  refreshRemainingQuotes: async () => {},
});

export const RemainingQuotesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [remainingQuotes, setRemainingQuotes] = useState<number | null>(null);
  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(null);

  const fetchRemainingQuotes = useCallback(async () => {
    try {
      /* console.log('Fetching remaining quotes...'); */
      const response = await axiosInstance.get('/api/remaining-quotes');
   /*    console.log('Response:', response.data); */
      setRemainingQuotes(response.data.remainingQuotes);
      setMonthlyLimit(response.data.monthlyLimit);
    } catch (error: any) {
      console.error('Error fetching remaining quotes:', error.response?.data || error.message);
    }
  }, []);

  useEffect(() => {
    fetchRemainingQuotes();
  }, [fetchRemainingQuotes]);

  const refreshRemainingQuotes = useCallback(async () => {
    await fetchRemainingQuotes();
  }, [fetchRemainingQuotes]);

  return (
    <RemainingQuotesContext.Provider
      value={{ remainingQuotes, monthlyLimit, refreshRemainingQuotes }}
    >
      {children}
    </RemainingQuotesContext.Provider>
  );
};

export const useRemainingQuotes = () => {
  const context = useContext(RemainingQuotesContext);
  if (context === undefined) {
    throw new Error('useRemainingQuotes must be used within a RemainingQuotesProvider');
  }
  return context;
};