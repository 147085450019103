// src/components/Logout.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Import useAuth

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth(); // Get setIsAuthenticated from context

  useEffect(() => {
    // Perform logout actions
    localStorage.removeItem('token');
    localStorage.removeItem('userData');

    // Update authentication state
    setIsAuthenticated(false);

    // Redirect to login page
    navigate('/login');
  }, [navigate, setIsAuthenticated]);

  return <div>Logging out...</div>; // Optionally, show a loading indicator
};

export default Logout;