import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import CowboyHatLogo from './CowboyHatLogo'; // Adjust the path based on your project structure

const EmailVerification: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [message, setMessage] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setVerificationStatus('error');
        setMessage('Verification token is missing.');
        return;
      }

      try {
        // Determine the API URL based on environment
        const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://cowboy-server-9qyo9.ondigitalocean.app/api'
          : 'http://localhost:3000/api';

        // Send verification request
        const response = await axios.get(`${apiUrl}/email/verify?token=${token}`);

        if (response.status === 200) {
          setVerificationStatus('success');
          setMessage('Your email has been successfully verified! Welcome to CowboyQuote.');

          // Automatically redirect after a short delay
          setTimeout(() => {
            navigate('/login');
          }, 5000); // Redirects after 5 seconds
        } else {
          // This block might not be necessary since non-200 statuses throw an error
          setVerificationStatus('error');
          setMessage(response.data.message || 'Email verification failed. Please try again or contact support.');
        }
      } catch (error: any) {
        console.error('Verification error:', error);

        if (error.response && error.response.data && error.response.data.message) {
          setMessage(error.response.data.message);
        } else {
          setMessage('Email verification failed. Please try again or contact support.');
        }
        setVerificationStatus('error');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div className="verification-container" style={{ textAlign: 'center', padding: '20px' }}>
      {verificationStatus === 'verifying' && <p>Verifying your email...</p>}

      {verificationStatus === 'success' && (
        <div>
          <CowboyHatLogo />
          <p style={{ fontSize: '1.2em', color: '#052F46', marginTop: '20px' }}>{message}</p>
          <button 
            onClick={() => navigate('/login')} 
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: '#052F46',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              maxWidth:'500px'
            }}
          >
            Proceed to Login
          </button>
        </div>
      )}

      {verificationStatus === 'error' && (
        <div>
          <p style={{ color: 'red', fontWeight: 'bold' }}>Email verification failed.</p>
          <p>{message}</p>
          <button 
            onClick={() => navigate('/resend-verification')} 
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: '#f44336',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Resend Verification Email
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
