// QuoteMap.tsx
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngExpression, Icon } from 'leaflet';
import { useTheme } from '@mui/material/styles';
import 'leaflet/dist/leaflet.css';

// Import marker icons
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Define the icon for markers
const defaultIcon = new Icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

interface Quote {
  quoteId: string;
  totalValue: number;
  location?: {
    latitude: number;
    longitude: number;
  };
  status: 'Active' | 'Accepted';
  templateName?: string;
  customerName?: string;
  activatedTime?: string;
  acceptedTime?: string;
}

interface QuoteMapProps {
  quotes: Quote[];
  companyLocation?: LatLngExpression;
}

// Map style URLs for light and dark modes
const mapStyles = {
  light: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
   dark: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'
};

const ChangeView = ({ center, zoom }: { center: LatLngExpression, zoom: number }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

function QuoteMap({ quotes, companyLocation }: QuoteMapProps) {
  const defaultCenter: LatLngExpression = [35.227085, -80.843124]; // Charlotte, NC
  const zoom = 10;

  // Get the current theme
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Custom Popup styles based on theme
  const popupStyle: React.CSSProperties = {
    color: isDarkMode ? '#FFFFFF' : '#000000',
    backgroundColor: isDarkMode ? '#333333' : '#FFFFFF',
    padding: '8px',
    borderRadius: '4px',
  };

  return (
    <MapContainer 
      center={companyLocation || defaultCenter} 
      zoom={zoom} 
      style={{ 
        height: '400px', 
        width: '100%',
        backgroundColor: isDarkMode ? '#242424' : '#ffffff',
        borderRadius: '8px',
      }}
    >
      <ChangeView center={companyLocation || defaultCenter} zoom={zoom} />
      <TileLayer
        url={isDarkMode ? mapStyles.dark : mapStyles.light}
        attribution={isDarkMode ? 
          '&copy; <a href="https://carto.com/attributions">CARTO</a> | Geocoding by <a href="https://locationiq.com/">LocationIQ</a>' :
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }
      />
      {quotes.map((quote) => {
        if (quote.location && quote.location.latitude && quote.location.longitude) {
          return (
            <Marker 
              key={quote.quoteId} 
              position={[quote.location.latitude, quote.location.longitude]}
              icon={defaultIcon}
            >
              <Popup>
                <div style={popupStyle}>
                  {quote.status === 'Active' ? (
                    <>
                      <strong>{quote.templateName}</strong><br />
                      Activated: {new Date(quote.activatedTime!).toLocaleString()}<br />
                      Total Value: ${quote.totalValue.toFixed(2)}
                    </>
                  ) : (
                    <>
                      <strong>{quote.customerName}</strong><br />
                      Accepted: {new Date(quote.acceptedTime!).toLocaleString()}<br />
                      Total Value: ${quote.totalValue.toFixed(2)}
                    </>
                  )}
                </div>
              </Popup>
            </Marker>
          );
        } else {
          return null;
        }
      })}
    </MapContainer>
  );
}

export default QuoteMap;