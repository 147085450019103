import React, { useState, useRef } from 'react';
import { Button, Paper, MenuItem, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DropdownMenuProps {
  options: { label: string; onClick: () => void }[];
  buttonText: string;
  disabled?: boolean;
}

const DropdownButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 120,
}));

const DropdownPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  right: 0,
  zIndex: 1,
  marginTop: theme.spacing(1),
  minWidth: 120,
}));

const DropdownMenu: React.FC<DropdownMenuProps> = ({ options, buttonText, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <DropdownButton
        ref={anchorRef}
        onClick={handleToggle}
        endIcon={<ExpandMoreIcon />}
        disabled={disabled}
        variant="contained"
        color="warning" 
      >
        {buttonText}
      </DropdownButton>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <DropdownPaper>
            {options.map((option, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  option.onClick();
                  setOpen(false);
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </DropdownPaper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DropdownMenu;