// pdfGenerator.ts

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { UserOptions } from "jspdf-autotable";
import html2canvas from 'html2canvas';

declare module 'jspdf' {
  interface jsPDF {
    autoTable: (options: UserOptions) => jsPDF;
  }
}

interface Service {
  name: string;
  description: string;
  quotePageDesc?: string;
  fineText?: string;
}

interface Quote {
  quoteId: string;
  services: string[];
  serviceValues: string[];
  totalValue: number;
  status: string;
  acceptedTime?: string;
}

interface QuoteTemplate {
  name: string;
  introParagraph: string;
  companyName: string;
  customServices: Service[];
  predefinedServiceIds: string[];
  customization: {
    primaryColor: string;
    secondaryColor: string;
    fontFamily: string;
    layout: 'list' | 'grid';
    showLogo: boolean;
    showIntro: boolean;
  };
}

interface CustomerData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  phone: string;
  signature?: string;
}

export const generateQuotePDF = async (
  quote: Quote,
  template: QuoteTemplate,
  customerData: CustomerData,
  viewportRatio: number, // Moved before optional parameters
  companyLogo?: string,
  predefinedServices?: Service[]
): Promise<Blob> => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "pt",
    format: "a4",
  });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Define margins
  const margin = 60;
  const bottomMargin = 60;

  // Function to draw dashed line
  const drawDashedLine = (startX: number, endX: number, y: number, dashLength: number = 3) => {
    doc.setLineDashPattern([dashLength], 0); // Add 0 as the dash phase
    doc.setLineWidth(0.5);
    doc.setDrawColor(200, 200, 200); // Light gray color
    doc.line(startX, y - 2, endX, y - 2);
    doc.setLineDashPattern([], 0); // Reset dash pattern with phase
  };

  try {
    // Set font
    const font = template.customization.fontFamily?.toLowerCase() === 'helvetica' ? 'helvetica' : 'helvetica';
    doc.setFont(font);
 /*    console.log('VIEWPORT RATIO: ', viewportRatio); */
    // Add company logo
    if (companyLogo) {
      const logoWidth = 120;
      const logoHeight = 60;
      const logoX = (pageWidth - logoWidth) / 2;
      doc.addImage(companyLogo, 'PNG', logoX, 50, logoWidth, logoHeight);
    }

    let yPos = 150;

    // Determine device type based on viewportRatio
    const isBrowser = viewportRatio < 2.5; // Adjust threshold as needed
    const descriptionFontSize = isBrowser ? '34px' : '8px';

    // Add services
    for (const serviceName of quote.services) {
      const index = quote.services.indexOf(serviceName);
      const serviceValue = quote.serviceValues[index];
      const serviceDetails = template.customServices.find((s) => s.name === serviceName) ||
                             predefinedServices?.find((s) => s.name === serviceName) || {
        name: serviceName,
        description: '',
        fineText: ''
      };

      // Add service name
      doc.setFontSize(16);
      doc.setTextColor(template.customization.secondaryColor || '#000000');
      const serviceNameWidth = doc.getTextWidth(serviceName);
      doc.text(serviceName, margin, yPos);

      // Add service value
      const valueText = `$${serviceValue}`;
      doc.setFontSize(12);
      const valueWidth = doc.getTextWidth(valueText);
      doc.setTextColor('#000000');
      doc.text(valueText, pageWidth - margin, yPos, { align: 'right' });

      // Draw dashed line between service name and value
      const lineStartX = margin + serviceNameWidth + 10; // Start 10pt after service name
      const lineEndX = pageWidth - margin - valueWidth - 10; // End 10pt before value
      drawDashedLine(lineStartX, lineEndX, yPos);

      yPos += 20;

      // Add service description
      let serviceDescription = serviceDetails.quotePageDesc || serviceDetails.description;
      if (serviceDescription) {
        doc.setFontSize(14);

        const serviceDescriptionHTML = `
          <div style="font-size:${descriptionFontSize}; color:#1E1F20;">
            ${serviceDescription}
          </div>
        `;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = serviceDescriptionHTML;
        document.body.appendChild(tempDiv);

        const canvas = await html2canvas(tempDiv, { scale: 3 });
        const imgData = canvas.toDataURL('image/jpeg', 0.7);

        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        doc.addImage(imgData, 'JPEG', margin, yPos, imgWidth, imgHeight);
        yPos += imgHeight + 40;

        document.body.removeChild(tempDiv);
      }
    }

    // Add total with dashed line
    doc.setFontSize(14);
    doc.setTextColor(template.customization.primaryColor || '#000000');
    const totalText = 'Total:';
    const totalValue = `$${quote.totalValue.toFixed(2)}`;
    const totalTextWidth = doc.getTextWidth(totalText);
    const totalValueWidth = doc.getTextWidth(totalValue);
    
    doc.text(totalText, margin, yPos + 10);
    doc.text(totalValue, pageWidth - margin, yPos + 10, { align: 'right' });

    /* // Draw dashed line for total
    const totalLineStartX = margin + totalTextWidth + 10;
    const totalLineEndX = pageWidth - margin - totalValueWidth - 10;
    drawDashedLine(totalLineStartX, totalLineEndX, yPos + 10); */

    // Define positions for signature and terms
    const termsY = pageHeight - bottomMargin;
    const signatureY = termsY - 200;
    const signatureWidth = 150;
    const signatureHeight = 50;
    const signatureX = pageWidth - margin - signatureWidth;

    // Add signature if quote is accepted
    if (quote.status === 'Accepted' && customerData.signature) {
      doc.addImage(customerData.signature, 'PNG', signatureX, signatureY, signatureWidth, signatureHeight);

      doc.setFontSize(10);
      doc.setTextColor('#000000');
      doc.text(`Signed by ${customerData.firstName} ${customerData.lastName}`, pageWidth - margin, signatureY + signatureHeight + 15, { align: 'right' });
      doc.text(`Accepted on ${new Date(quote.acceptedTime!).toLocaleString()}`, pageWidth - margin, signatureY + signatureHeight + 30, { align: 'right' });
    }

    // Add terms and conditions
    if (quote.services.length > 0) {
      let termsContent = '';
      quote.services.forEach((serviceName) => {
        const serviceDetails = template.customServices.find((s) => s.name === serviceName) ||
                             predefinedServices?.find((s) => s.name === serviceName) || {
          name: serviceName,
          description: '',
          fineText: ''
        };
        if (serviceDetails.fineText) {
          termsContent += `${serviceDetails.fineText}\n\n`;
        }
      });

      if (termsContent) {
        doc.setFontSize(12);
        doc.setTextColor('#000000');
        doc.text('Terms and Conditions:', margin, termsY - 80);

        doc.setFontSize(9);
        const fineTextLines = doc.splitTextToSize(termsContent, pageWidth - 2 * margin);
        doc.text(fineTextLines, margin, termsY - 60);

        doc.setLineWidth(0.5);
        doc.line(margin, termsY - 110, pageWidth - margin, termsY - 110);
      }
    }

    return doc.output('blob');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF');
  }
};