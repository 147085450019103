import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import cowboyHatLogo from '../logo192.png'; 

const AnimatedLogo = styled('img')<{ $isAnimating: boolean }>(({ $isAnimating }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  transition: 'transform 0.3s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  animation: $isAnimating ? 'wiggle 0.5s ease-in-out' : 'none',
  '@keyframes wiggle': {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(-10deg)' },
    '50%': { transform: 'rotate(10deg)' },
    '75%': { transform: 'rotate(-10deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
}));

const CowboyHatLogo: React.FC = () => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 500); // Animation duration
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 5,
        maxWidth: '100%',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AnimatedLogo
        src={cowboyHatLogo}
        alt="Cowboy Hat Logo"
        $isAnimating={isAnimating}
        onClick={handleClick}
      />
    </Box>
  );
};

export default CowboyHatLogo;