// src/components/NavBar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const NavBar: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <nav className="navbar">
      {/* Wrap the logo and brand name in a container */}
      <div className="navbar-brand" style={{ display: 'flex', alignItems: 'center' }}>
        <img 
          src="/logo192.png" 
          alt="CowboyQuote Logo" 
          style={{ 
            width: 30, 
            height: 30, 
            marginRight: 5, // Add some space between the logo and text
            transition: 'transform 0.3s',
          }} 
        />
        <span>CowboyQuote</span>
      </div>

      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        {!isAuthenticated && (
          <>
            <li><Link to="/signup">Sign Up</Link></li>
            <li><Link to="/login">Login</Link></li>
          </>
        )}
        {isAuthenticated && (
          <>
            <li><Link to="/dashboard">Dashboard</Link></li>
            <li><Link to="/logout">Logout</Link></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;