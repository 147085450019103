// frontend/src/components/Settings.tsx

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  Divider,
  Paper,
  Autocomplete,
  CircularProgress as MuiCircularProgress,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import axiosInstance from '../utils/axiosConfig';
import { getDecodedToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { debounce } from 'lodash';

// Interface Definitions
interface CompanySettings {
  companyName: string;
  companyPhoneNumber: string;
  companyLogo: string | null;
  planTier: string;
  whoWeAreText: string;
  googleMapEmbed: string;
  websiteLink: string;
  compLocation: {
    latitude: number;
    longitude: number;
  } | null;
}

interface UserSettings {
  whoIAmText: string;
}

interface LocationSuggestion {
  display_name: string;
  lat: string;
  lon: string;
}

// Default Values
const defaultCompanySettings: CompanySettings = {
  companyName: '',
  companyPhoneNumber: '',
  companyLogo: null,
  planTier: 'Free',
  whoWeAreText: '',
  googleMapEmbed: '',
  websiteLink: '',
  compLocation: null,
};

const defaultUserSettings: UserSettings = {
  whoIAmText: '',
};

// Styled Components for Consistency
const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}));

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Settings: React.FC = () => {
  const decodedToken = getDecodedToken();
  const userRole = decodedToken?.role || '';
  const userId = decodedToken?.userId || '';

  const [activeTab, setActiveTab] = useState(0);

  // Company Settings States
  const [companySettings, setCompanySettings] = useState<CompanySettings>(defaultCompanySettings);
  const [companyLoading, setCompanyLoading] = useState(true);
  const [companyError, setCompanyError] = useState<string | null>(null);
  const [companySuccess, setCompanySuccess] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);

  // User Settings States
  const [userSettings, setUserSettings] = useState<UserSettings>(defaultUserSettings);
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState<string | null>(null);
  const [userSuccess, setUserSuccess] = useState<string | null>(null);

  // Plan Name State
  const [planName, setPlanName] = useState<string>('Free');

  // Base Location States
  const [baseLocation, setBaseLocation] = useState<{
    displayName: string;
    latitude: number;
    longitude: number;
  } | null>(null);
  const [locationInput, setLocationInput] = useState<string>('');
  const [locationOptions, setLocationOptions] = useState<LocationSuggestion[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [locationSuccess, setLocationSuccess] = useState<string | null>(null);

  // Navigation Hook
  const navigate = useNavigate();

  // Theme and Responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch Company and User Settings
  useEffect(() => {
    if (userRole) {
      if (userRole === 'Owner') {
        fetchCompanySettings();
      }
      fetchUserSettings();
    }
  }, [userRole]);

  const fetchCompanySettings = async () => {
    try {
      const response = await axiosInstance.get('/api/compSettings');

      setCompanySettings({
        companyName: response.data.companyName ?? '',
        companyPhoneNumber: response.data.companyPhoneNumber ?? '',
        companyLogo: response.data.companyLogo ?? null,
        planTier: response.data.planTier ?? 'Free',
        whoWeAreText: response.data.whoWeAreText ?? '',
        googleMapEmbed: response.data.googleMapEmbed ?? '',
        websiteLink: response.data.websiteLink ?? '',
        compLocation: response.data.compLocation ?? null,
      });

      // Set Base Location if exists
      if (response.data.compLocation) {
        // Optionally, you can reverse geocode to get the display name
        // For simplicity, we'll leave it as empty string
        setBaseLocation({
          displayName: '', // You might want to fetch display name based on coordinates
          latitude: response.data.compLocation.latitude,
          longitude: response.data.compLocation.longitude,
        });
      }

      // Fetch the plan's name based on planTier
      if (response.data.planTier && response.data.planTier !== 'Free') {
        try {
          const planResponse = await axiosInstance.get(`/api/plans/${response.data.planTier}`);
          setPlanName(planResponse.data.name);
        } catch (error) {
          console.error('Error fetching plan name:', error);
          setPlanName('Free');
        }
      } else {
        setPlanName('Free');
      }

      setCompanyLoading(false);
    } catch (error: any) {
      console.error('Error fetching company settings:', error);
      setCompanyError('Failed to load company settings. Please try again.');
      setCompanyLoading(false);
    }
  };

  const fetchUserSettings = async () => {
    try {
      const response = await axiosInstance.get(`/api/users/${userId}`);

      setUserSettings({
        whoIAmText: response.data.whoIAmText ?? '',
      });

      setUserLoading(false);
    } catch (error: any) {
      console.error('Error fetching user settings:', error);
      setUserError('Failed to load user settings. Please try again.');
      setUserLoading(false);
    }
  };

  // Handle Company Settings Changes
  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanySettings({ ...companySettings, [e.target.name]: e.target.value });
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleCompanySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setCompanyLoading(true);
    setCompanyError(null);
    setCompanySuccess(null);

    try {
      // Prepare form data
      const formData = new FormData();
      formData.append('companyName', companySettings.companyName);
      formData.append('companyPhoneNumber', companySettings.companyPhoneNumber);
      formData.append('planTier', companySettings.planTier);
      formData.append('whoWeAreText', companySettings.whoWeAreText);
      formData.append('googleMapEmbed', companySettings.googleMapEmbed);
      formData.append('websiteLink', companySettings.websiteLink);
      if (logoFile) {
        formData.append('companyLogo', logoFile);
      }

      // Submit form data
      const response = await axiosInstance.put('/api/compSettings', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Update local state with the response data
      setCompanySettings({
        companyName: response.data.companyName ?? '',
        companyPhoneNumber: response.data.companyPhoneNumber ?? '',
        companyLogo: response.data.companyLogo ?? null,
        planTier: response.data.planTier ?? 'Free',
        whoWeAreText: response.data.whoWeAreText ?? '',
        googleMapEmbed: response.data.googleMapEmbed ?? '',
        websiteLink: response.data.websiteLink ?? '',
        compLocation: response.data.compLocation ?? null,
      });

      // Update the base location if it exists
      if (response.data.compLocation) {
        setBaseLocation({
          displayName: '', // Optionally, reverse geocode to get the display name
          latitude: response.data.compLocation.latitude,
          longitude: response.data.compLocation.longitude,
        });
      }

      // Update the planName based on the new planTier
      if (response.data.planTier && response.data.planTier !== 'Free') {
        try {
          const planResponse = await axiosInstance.get(`/api/plans/${response.data.planTier}`);
          setPlanName(planResponse.data.name);
        } catch (error) {
          console.error('Error fetching plan name:', error);
          setPlanName('Unknown Plan');
        }
      } else {
        setPlanName('Free');
      }

      setCompanySuccess('Company settings updated successfully');
      setLogoFile(null);
    } catch (error: any) {
      console.error('Error updating company settings:', error);
      setCompanyError('Failed to update company settings. Please try again.');
    } finally {
      setCompanyLoading(false);
    }
  };

  // Handle User Settings Changes
  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSettings({ ...userSettings, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUserLoading(true);
    setUserError(null);
    setUserSuccess(null);

    try {
      const response = await axiosInstance.put(`/api/users/update/${userId}`, userSettings);

      // Update local state with the response data
      setUserSettings({
        whoIAmText: response.data.whoIAmText ?? '',
      });
      setUserSuccess('User settings updated successfully');
    } catch (error: any) {
      console.error('Error updating user settings:', error);
      if (error.response) {
        setUserError(error.response.data.message || 'Failed to update user settings. Please try again.');
      } else if (error.request) {
        setUserError('No response from server. Please check your network connection.');
      } else {
        setUserError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setUserLoading(false);
    }
  };

  // Handle Tab Change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // ====================== Added useEffect Hooks ======================
  // 1. For Company Success Notification
  useEffect(() => {
    if (companySuccess) {
      const timer = setTimeout(() => {
        setCompanySuccess(null);
      }, 2000); // 2 seconds

      // Cleanup the timer if the component unmounts or companySuccess changes
      return () => clearTimeout(timer);
    }
  }, [companySuccess]);

  // 2. For User Success Notification
  useEffect(() => {
    if (userSuccess) {
      const timer = setTimeout(() => {
        setUserSuccess(null);
      }, 2000); // 2 seconds

      // Cleanup the timer if the component unmounts or userSuccess changes
      return () => clearTimeout(timer);
    }
  }, [userSuccess]);

  // ====================== Added Location Handlers ======================
  // Fetch city suggestions
  const fetchCitySuggestions = async (query: string) => {
    if (query.length < 3) return; // Don't search if query is less than 3 characters
    try {
      setLocationLoading(true);
      const response = await axiosInstance.get('/api/locationiq/cities', {
        params: { q: query },
      });
      setLocationOptions(response.data);
    } catch (error: any) {
      console.error('Error fetching city suggestions:', error);
      setLocationError('Failed to fetch city suggestions.');
      setLocationOptions([]);
    } finally {
      setLocationLoading(false);
    }
  };

  // Debounced version to prevent excessive API calls
  const debouncedFetchCitySuggestions = useCallback(
    debounce((query: string) => fetchCitySuggestions(query), 300),
    []
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedFetchCitySuggestions.cancel();
    };
  }, [debouncedFetchCitySuggestions]);

  // Handle Location Selection
  const handleLocationSelect = (event: React.SyntheticEvent<Element, Event>, newValue: LocationSuggestion | null) => {
    if (newValue) {
      const { display_name, lat, lon } = newValue;
      setBaseLocation({
        displayName: display_name,
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
  };

  // Handle Location Submit
  const handleLocationSubmit = async () => {
    if (!baseLocation) return;
    setLocationLoading(true);
    setLocationError(null);
    setLocationSuccess(null);
    try {
      const response = await axiosInstance.post('/api/compSettings/setLocation', {
        latitude: baseLocation.latitude,
        longitude: baseLocation.longitude,
      });

      setCompanySettings((prev) => ({
        ...prev,
        compLocation: response.data.compLocation,
      }));

      setLocationSuccess('Base location updated successfully.');
    } catch (error: any) {
      console.error('Error updating base location:', error);
      setLocationError('Failed to update base location. Please try again.');
    } finally {
      setLocationLoading(false);
    }
  };

  // =====================================================================

  // Handle Sidebar Navigation (if applicable)
  // ... (Assuming Sidebar is handled elsewhere or not needed in this component)

  if ((userRole === 'Owner' && companyLoading) || userLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', bgcolor: 'background.default' }}>
      <Box sx={{ width: '100%', p: 2, overflowY: 'auto' }}>
        <Typography variant="h4" gutterBottom align='center'>
          Settings
        </Typography>
        {/* Optional: Add a global action button here if needed */}

        {/* Tabs Section */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              mb: 3,
              // Customize the indicator color to 'warning.main'
              '& .MuiTabs-indicator': {
                backgroundColor: 'warning.main',
              },
            }}
            // Ensure text color is inherited to allow custom colors via sx
            textColor="inherit"
          >
            {userRole === 'Owner' && (
              <Tab
                label="Company Settings"
                sx={(theme) => ({
                  color: 'warning.main',
                  '&.Mui-selected': {
                    color: 'warning.main',
                  },
                  '&:hover': {
                    color: alpha(theme.palette.common.white, 1),
                    backgroundColor: alpha(theme.palette.warning.main, 0.8),
                    cursor: 'pointer',
                  },
                })}
              />
            )}
            <Tab
              label="User Settings"
              sx={(theme) => ({
                color: 'warning.main',
                '&.Mui-selected': {
                  color: 'warning.main',
                },
                '&:hover': {
                  color: alpha(theme.palette.common.white, 1),
                  backgroundColor: alpha(theme.palette.warning.main, 0.8),
                  cursor: 'pointer',
                },
              })}
            />
          </Tabs>
        </Box>

        {/* Tab Panels */}
        <Box sx={{ flexGrow: 1, p: isMobile ? 2 : 4 }}>
          {/* Company Settings Panel */}
          {userRole === 'Owner' && (
            <TabPanel value={activeTab} index={0}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h5" gutterBottom>
                  Company Settings
                </Typography>
                <Divider sx={{ mb: 3 }} />
                {companyError && <Alert severity="error" sx={{ mb: 2 }}>{companyError}</Alert>}
                {companySuccess && <Alert severity="success" sx={{ mb: 2 }}>{companySuccess}</Alert>}
                <form onSubmit={handleCompanySubmit}>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
                    <TextField
                      label="Company Name"
                      name="companyName"
                      value={companySettings.companyName}
                      onChange={handleCompanyChange}
                      required
                      fullWidth
                      disabled 
                    />
                    <TextField
                      label="Company Phone Number"
                      name="companyPhoneNumber"
                      value={companySettings.companyPhoneNumber}
                      onChange={handleCompanyChange}
                      fullWidth
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'warning.main',
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'warning.main', 
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mt: 2 }}>
                    <TextField
                      label="Plan Tier"
                      name="planTier"
                      value={planName}
                      fullWidth
                      disabled // Displaying plan name instead of ObjectId
                    />
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => navigate('/dashboard/subscription')}
                      sx={{ height: '56px' }}
                    >
                      Manage Subscription
                    </Button>
                  </Box>
                  <TextField
                    label="Who We Are"
                    name="whoWeAreText"
                    value={companySettings.whoWeAreText}
                    onChange={handleCompanyChange}
                    margin="normal"
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                  <TextField
                    label="Google Map Embed"
                    name="googleMapEmbed"
                    value={companySettings.googleMapEmbed}
                    onChange={handleCompanyChange}
                    margin="normal"
                    multiline
                    rows={2}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                  <TextField
                    label="Website Link"
                    name="websiteLink"
                    value={companySettings.websiteLink}
                    onChange={handleCompanyChange}
                    margin="normal"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'warning.main',
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'warning.main', 
                      },
                    }}
                  />
                  <Box sx={{ mt: 3, mb: 3 }}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="logo-file"
                      type="file"
                      onChange={handleLogoChange}
                    />
                    <label htmlFor="logo-file">
                    <Button
                      variant="outlined"
                      component="span"
                      sx={{
                        color: 'warning.main', // Text color
                        borderColor: 'warning.main', // Border color
                        '&:hover': {
                          borderColor: 'warning.dark', // Border color on hover
                        
                          color: 'warning.dark', // Text color on hover
                        },
                      }}
                    >
                        Upload Logo
                      </Button>
                    </label>
                    {logoFile && <Typography variant="body2" sx={{ ml: 2, display: 'inline' }}>{logoFile.name}</Typography>}
                  </Box>
                  {companySettings.companyLogo && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>Current Logo:</Typography>
                      <img
                        src={`${(process.env.REACT_APP_API_URL || 'http://localhost:3000').replace(/\/$/, '')}/${companySettings.companyLogo.replace(/^\//, '')}`}
                        alt="Company Logo"
                        style={{ maxWidth: '200px', height: 'auto' }}
                      />
                    </Box>
                  )}
                  <Button type="submit" variant="contained" color="warning" fullWidth={!isMobile} sx={{ mt: 2 }}>
                    Save Company Settings
                  </Button>
                </form>

              {/* Base Location Section */}
    <Box sx={{ mt: 6 }}>
      <Typography variant="h6" gutterBottom>
        Base Location
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Autocomplete
          options={locationOptions}
          getOptionLabel={(option: LocationSuggestion) => option.display_name}
          onInputChange={(event, newInputValue) => {
            setLocationInput(newInputValue);
            if (newInputValue.length >= 3) {
              debouncedFetchCitySuggestions(newInputValue);
            } else {
              setLocationOptions([]);
            }
          }}
          onChange={handleLocationSelect}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for a city"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          sx={{
            width:'100%',
            mr:2,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'warning.main',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'warning.main', 
            },
          }}
          disableClearable
        />
        <Button
          variant="contained"
          color="info"
          onClick={handleLocationSubmit}
          disabled={locationLoading || !baseLocation}
          sx={{ height: '56px', flexGrow: 1 }}
        >
          {locationLoading ? <CircularProgress size={24} /> : 'Update Base Location'}
        </Button>
      </Box>
      {locationError && <Alert severity="error" sx={{ mb: 2 }}>{locationError}</Alert>}
      {locationSuccess && <Alert severity="success" sx={{ mb: 2 }}>{locationSuccess}</Alert>}
      {baseLocation && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Selected Location: <strong>{baseLocation.displayName || 'N/A'}</strong>
        </Typography>
      )}
      {companySettings.compLocation && (
        <Typography variant="body1">
          Current Base Location:
          <strong>
            {' '}
            Latitude: {companySettings.compLocation.latitude}, Longitude: {companySettings.compLocation.longitude}
          </strong>
        </Typography>
      )}
    </Box>
              </Paper>
            </TabPanel>
          )}

          {/* User Settings Panel */}
          <TabPanel value={activeTab} index={userRole === 'Owner' ? 1 : 0}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom>
                User Settings
              </Typography>
              <Divider sx={{ mb: 3 }} />
              {userError && <Alert severity="error" sx={{ mb: 2 }}>{userError}</Alert>}
              {userSuccess && <Alert severity="success" sx={{ mb: 2 }}>{userSuccess}</Alert>}
              <form onSubmit={handleUserSubmit}>
                <TextField
                  label="Who I Am"
                  name="whoIAmText"
                  value={userSettings.whoIAmText}
                  onChange={handleUserChange}
                  margin="normal"
                  multiline
                  rows={4}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'warning.main',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: 'warning.main', 
                    },
                  }}
                />
                <Button type="submit" variant="contained" color="warning" fullWidth={!isMobile} sx={{ mt: 2 }}>
                  Save User Settings
                </Button>
              </form>
            </Paper>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;