import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
    return (
        <div className="home-container">
            <section className="hero-section">
                <h1>Streamline Your Quoting Process</h1>
                <p>Empower your service-based business with efficient quote management</p>
                <Link to="/signup" className="cta-button">Get Started Free</Link>
            </section>

            <section className="features">
                <div className="feature-card">
                    <div className="feature-icon">📊</div>
                    <h3>Multi-Company Support</h3>
                    <p>Manage multiple businesses with ease</p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">📄</div>
                    <h3>Template-Based Quoting</h3>
                    <p>Create reusable templates for quick quoting</p>
                </div>
                <div className="feature-card">
                    <div className="feature-icon">📱</div>
                    <h3>Field Use & QR Integration</h3>
                    <p>Generate and scan quotes on-the-go</p>
                </div>
            </section>
        </div>
    );
};

export default Home;