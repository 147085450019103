import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';

// StyledLabel now accepts a color prop
const StyledSelect = styled.select`
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;

  &:focus {
    outline: none;
    border-color: #1976d2;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
  }
`;

const StyledLabel = styled.label<{ color?: string }>`
  display: block;
  margin-bottom: 8px;
  font-size: 0.875rem;
  color: ${({ color }) => color || 'rgba(0, 0, 0, 0.6)'};
`;

interface CustomDropdownProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  options: { value: string; label: string }[];
  id: string;
  labelColor?: string; // Optional prop for label color, e.g., 'warning.main'
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  value,
  onChange,
  options,
  id,
  labelColor,
}) => {
  const theme = useTheme();

  // Function to resolve color from theme.palette based on a string like 'warning.main'
  const resolveThemeColor = (colorPath: string): string | undefined => {
    const colorParts = colorPath.split('.');
    let colorValue: any = theme.palette;

    for (const part of colorParts) {
      colorValue = colorValue[part];
      if (!colorValue) break;
    }

    return typeof colorValue === 'string' ? colorValue : undefined;
  };

  const resolvedLabelColor = labelColor
    ? resolveThemeColor(labelColor)
    : undefined;

  return (
    <div>
      <StyledLabel htmlFor={id} color={resolvedLabelColor}>
        {label}
      </StyledLabel>
      <StyledSelect
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </div>
  );
};

export default CustomDropdown;