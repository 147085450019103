// src/components/Login.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useAuth } from '../AuthContext';
import CowboyHatLogo from './CowboyHatLogo'; // Ensure this path is correct
import '../App.css'; // Ensure this path is correct based on your project structure

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/login', {
                email,
                password,
                companyName
            });
            console.log('Login successful', response.data);

            // Store the token in localStorage
            localStorage.setItem('token', response.data.token);

            // Store user data in localStorage
            const userData = {
                userId: response.data.userId,
                name: response.data.name,
                email: response.data.email,
                companyId: response.data.companyId,
                companyName: response.data.companyName,
                role: response.data.role
            };
            localStorage.setItem('userData', JSON.stringify(userData));

            // Update the authentication state
            setIsAuthenticated(true);

            // Navigate to dashboard
            navigate('/dashboard');
        } catch (err: any) {
            setError(err.response?.data?.message || 'Login failed. Please check your credentials.');
            console.error('Login error:', err);
        }
    };

    return (
        <div className="auth-container login-container">
            <CowboyHatLogo /> {/* Clickable logo redirects to home */}
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        placeholder="Enter your email"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Enter your password"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="companyName">Company Name:</label>
                    <input
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                        placeholder="Enter your company name"
                    />
                </div>
                <button type="submit" className="submit-button">Login</button>
            </form>
            <p>
                <a href="/forgot-password">Forgot Password?</a>
            </p>
        </div>
    );
};

export default Login;
