// src/App.tsx

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import QuoteTemplatePage from './components/QuoteTemplatePage';
import ViewQuote from './components/ViewQuote';  
import EmailVerification from './components/EmailVerification';
import ResendVerification from './components/ResendVerification';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SubscriptionManager from './components/SubscriptionManager';
import PredefinedServicesPage from './components/PredefinedServicesPage';
import ViewAllQuotes from './components/ViewAllQuotes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import '@fontsource/poppins';
import './App.css';
import CustomerQuoteLandingPage from './components/CustomerQuoteLanding';
import QuotaIndicator from './components/QuotaIndicator';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from './AuthContext';
import { RemainingQuotesProvider } from './RemainingQuotesContext';

// Create a context for the night mode
export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const App: React.FC = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const savedMode = localStorage.getItem('colorMode');
    if (savedMode) {
      setMode(savedMode as 'light' | 'dark');
    }
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('colorMode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: mode === 'light' ? '#052F46' : '#312722',
          },
          secondary: {
            main: mode === 'light' ? '#C6623A' : '#C6623A',
          },
          info:{
            main: mode === 'light' ? '#064454' : '#00647A',
          },
          background: {
            default: mode === 'light' ? '#E7E7E7' : '#1E1F20',
            paper: mode === 'light' ? '#ffffff' : '#041821',
          },
        },
        typography: {
          fontFamily: 'Poppins, Arial, sans-serif',
        },
      }),
    [mode],
  );

  // Updated ConditionalNavBar to hide NavBar on '/dashboard' and '/quote' routes
  const ConditionalNavBar = () => {
    const location = useLocation();
    const hideNavbarPaths = ['/dashboard', '/quote']; // Add more paths as needed

    // Check if current path starts with any path in hideNavbarPaths
    const shouldHideNavbar = hideNavbarPaths.some((path) =>
      location.pathname.startsWith(path)
    );

    return !shouldHideNavbar ? <NavBar /> : null;
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Router>
              <ConditionalNavBar />
              {isAuthenticated ? (
                <RemainingQuotesProvider>
                  <QuotaIndicator />
                  <Routes>
                    {/* Authenticated routes */}
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/dashboard/*" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/quote-template/:templateId" element={<QuoteTemplatePage />} />
                    <Route path="/all-quotes" element={<ViewAllQuotes />} />
                    <Route path="/internal/quote/:quoteId" element={<ViewQuote />} />
                    <Route path="/predefined-services" element={<PredefinedServicesPage />} />
                    <Route path="/subscription" element={<SubscriptionManager />} />
                    {/* Add the quote route here */}
                    <Route path="/quote/:quoteId/:companyName" element={<CustomerQuoteLandingPage />} />
                    {/* Redirect unknown paths to dashboard */}
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </RemainingQuotesProvider>
              ) : (
                <Routes>
                  {/* Unauthenticated routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/verify-email" element={<EmailVerification />} />
                  <Route path="/resend-verification" element={<ResendVerification />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  {/* Routes accessible to all users */}
                  <Route path="/quote/:quoteId/:companyName" element={<CustomerQuoteLandingPage />} />
                  {/* Redirect unknown paths to home */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              )}
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;